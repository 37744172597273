import React from 'react';
import ListItem from './ListItem';

export default function ListSelector({
  list,
  listType,
  addListItem,
  removeListItem,
  selectedItems,
  maxItems
}) {
  const isItemEnabled = (itemId) => selectedItems.some((item) => item._id === itemId);

  const handleItemClick = (item) => {
    const isCurrentlyEnabled = isItemEnabled(item._id);

    if (maxItems === 1) {
      // Single selection mode (radio button behavior)
      if (isCurrentlyEnabled) {
        // If clicking the same item that's already selected, do nothing
        // This maintains radio button behavior - can't deselect current selection
        return;
      }

      // Find the currently selected item (if any) before making changes
      const currentSelected = selectedItems.find((selected) => selected._id !== item._id);

      // When selecting a new item:
      // 1. First add the new selection
      addListItem(item, listType);
      // 2. Then remove the previous selection (if any)
      if (currentSelected) {
        removeListItem(currentSelected, listType);
      }
      return;
    }

    // Multiple selection mode (checkbox-like behavior)
    if (isCurrentlyEnabled) {
      removeListItem(item, listType);
    } else if (selectedItems.length < maxItems) {
      addListItem(item, listType);
    }
  };

  return (
    <ul className="list-selector">
      {list.map((item) => (
        <ListItem
          key={item._id}
          item={item}
          isEnabled={isItemEnabled(item._id)}
          onClick={() => handleItemClick(item)}
        />
      ))}
    </ul>
  );
}
