import React, { useState } from 'react';
import Header from '../partials/Header';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../utils';

export default function VideoProcessing({ orgPrefix }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const backToMenuButtonHandler = () => {
    navigate('/my-profile');
  };

  return (
    <>
      <div className="fixed bg-white shadow-xl sm-popup helpline p-8 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-[80%]">
        <div className="content text-center">
          <img src="/images/icons/video-record.svg" className="roundAnimation w-32 mx-auto mb-6" />

          <h2 className="heading">{getTranslation(t, 'videoProcessing.title', orgPrefix)}</h2>
          <p className="p mb-8 ">{getTranslation(t, 'videoProcessing.subTitle', orgPrefix)}</p>
          <p>
            <b>{getTranslation(t, 'videoProcessing.important', orgPrefix)}</b>{' '}
            {getTranslation(t, 'videoProcessing.note', orgPrefix)}
          </p>
          <button className="btn btn-primary w-fit mt-10" onClick={backToMenuButtonHandler}>
            <div className="flex items-center">
              <span>{getTranslation(t, 'videoProcessing.button', orgPrefix)}</span>
            </div>
          </button>
        </div>
      </div>
    </>
  );
}
