import React from 'react';

export default function ProgressBar({ progressPercentage }) {
  return (
    <div className="progress-bar h-2">
      <div
        style={{ width: `${progressPercentage}%` }}
        className={`h-full rounded-full bg-black`}></div>
    </div>
  );
}
