import { isEmpty, findIndex } from 'lodash';

import roles from '../data/roles';
const isLinkDisabled = (link, profile) => {
  if (link === '/profile-data') {
    return false;
  } else if (link === '/profile-image') {
    return isEmpty(profile.firstName);
  } else if (link === '/strengths') {
    return isEmpty(profile.image);
  } else if (link === '/video-competitor') {
    return isEmpty(profile.strengths);
  } else if (link === '/video-1') {
    return isEmpty(profile.strengths);
  } else if (link === '/video-2') {
    return findVideoById(profile.videos?.videos, 1) < 0;
  } else if (link === '/video-3') {
    return findVideoById(profile.videos?.videos, 2) < 0;
  } else if (link === '/video-4') {
    return findVideoById(profile.videos?.videos, 3) < 0;
  } else if (link === '/video-5') {
    return findVideoById(profile.videos?.videos, 4) < 0;
  } else if (link === '/video-6') {
    return findVideoById(profile.videos?.videos, 5) < 0;
  } else if (link === '/video-7') {
    return findVideoById(profile.videos?.videos, 6) < 0;
  } else if (link === '/champion-profile') {
    if (
      profile.profileType === roles.CHAMPION ||
      profile.profileType === roles.CH_CHAMPION ||
      profile.profileType === roles.CH_NATIONAL_CHAMPION
    )
      return findVideoById(profile.videos?.videos, 7) < 0;
    else return isEmpty(profile.strengths);
  }
};
const numberToRank = {
  1: 'Or',
  2: 'Argent',
  3: 'Bronze',
  4: 'Finaliste',
  5: 'Finaliste',
  6: 'Finaliste',
  7: 'Finaliste',
  8: 'Finaliste',
  9: 'Participant-e'
};

const getRank = (number = '') => {
  if (number.toString().length > 1)
    return Object.keys(numberToRank).find((item) => number.includes(item));
  else return numberToRank[number] || '';
};

const findVideoById = (videos = [], findId) =>
  findIndex(videos, ({ videoID }) => parseInt(videoID) === findId);

export { isLinkDisabled, getRank };
