import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { isLinkDisabled } from '../../service/internalService';
import FullVideoUploadFlow from '../../components/video/fullVideoUploadFlow';
import { ProfileContext } from '../../contexts/ProfileContext';

export default function VideoCompetitor() {
  const [profile] = useContext(ProfileContext);

  const navigate = useNavigate();
  useEffect(() => {
    if (!profile?.loaded) return;
    if (isLinkDisabled(window.location.pathname, profile)) navigate('/my-profile');
  }, [profile?.loaded]);

  return <FullVideoUploadFlow id={'video-comp-1'} videoID={'comp-1'} />;
}
