import React from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';

const Loader = ({ transparent = false }) => {
  return (
    <div
      className={`z-[10000] ${
        transparent ? 'bg-white/80' : 'bg-white'
      } fixed top-0 left-0 w-full h-full flex items-center justify-center`}>
      <div>
        <PropagateLoader color="#545454" />
      </div>
    </div>
  );
};

export default Loader;
