import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import i18next from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import './App.scss';
import {
  PgMyProfile,
  PgProfileImage,
  PgStrengthsAndRoles,
  ProfileData,
  Magic,
  PgSplash,
  ExampleVideo,
  Login,
  PgVideoTips
} from './pages/index';
import PgVideos from './pages/PgVideos';
import { ProfileContext } from './contexts/ProfileContext';
import { Routes, Route, Navigate, useNavigate, useSearchParams, Outlet } from 'react-router-dom';
import { scrollToFragment } from 'scroll-to-fragment';
import { fetchTranslations, getUserProfile, profileApi } from './service';
import { ToastContainer } from 'react-toastify';
import DeviceTypePopup from './components/popups/DeviceTypePopup';
import Header from './components/partials/Header';
import BugReport from './components/common/bugReport';

import {
  RegionLogin,
  RegionApp,
  WelcomeRegion,
  RegionVideosMenu,
  RegionMagic,
  VideoRegion
} from './pages/regional';
import Loader from './components/common/Loader';
// import { trackEvent } from './utils';
import { changeFavicon } from './utils';

function App() {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  const loadTranslations = async () => {
    try {
      i18next.init({
        interpolation: { escapeValue: false },
        saveMissing: true,
        returnEmptyString: false
      });
      i18next.languages = ['en', 'fr', 'de', 'it'];
      i18next.use(initReactI18next).init({
        fallbackLng: 'en',
        lng: 'en',
        ns: ['translations'],
        defaultNS: 'translations'
      });

      const { data: { en, de, fr, it } = {} } = await fetchTranslations();

      i18next.addResourceBundle('en', 'translations', en);
      i18next.addResourceBundle('fr', 'translations', fr);
      i18next.addResourceBundle('de', 'translations', de);
      i18next.addResourceBundle('it', 'translations', it);

      i18next.changeLanguage('en'); // Initial language
    } catch (error) {
      console.log('Error', error);
    } finally {
      setTranslationsLoaded(true);
    }
  };

  useEffect(() => {
    if (translationsLoaded) return;

    loadTranslations();
  }, [translationsLoaded]);

  if (!translationsLoaded) return <Loader />;

  return (
    <div className={'container mx-auto overflow-x-hidden'}>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/magic" element={<Magic />} />

        <Route path="/" element={<BaseContainer />}>
          <Route path="splash" element={<PgSplash />} />
          <Route path="my-profile" element={<PgMyProfile />} />
          <Route path="profile-image" element={<PgProfileImage />} />
          <Route path="profile-data" element={<ProfileData />} />
          <Route path="strengths" element={<PgStrengthsAndRoles />} />
          <Route path="video-competitor" element={<PgVideos.VideoCompetitor />} />
          <Route path="video-tips/:id" element={<PgVideoTips />} />
          <Route path="video/:id" element={<PgVideos.Video />} />
          <Route path="example-video/:id" element={<ExampleVideo />} />
        </Route>

        {/* Regional login and video uploader - Don't know its use currently */}
        <Route path="/region" element={<RegionApp />}>
          <Route
            path="login"
            element={
              <TokenExistence>
                <RegionLogin />
              </TokenExistence>
            }
          />
          <Route
            path="magic"
            element={
              <TokenExistence>
                <RegionMagic />
              </TokenExistence>
            }
          />
          <Route
            path="welcome-region"
            element={
              <ProtectedRegion>
                <WelcomeRegion />
              </ProtectedRegion>
            }
          />
          <Route
            path="video-region"
            element={
              <ProtectedRegion>
                <RegionVideosMenu />
              </ProtectedRegion>
            }
          />
          <Route
            path="video/:videoID/:id"
            element={
              <ProtectedRegion>
                <VideoRegion />
              </ProtectedRegion>
            }
          />
        </Route>

        {/* Not found */}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>

      {!window?.location?.origin?.includes('localhost') && <BugReport />}
    </div>
  );
}

export default App;

function BaseContainer() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [profile, setProfileData] = useContext(ProfileContext);
  const [isLoading, setLoading] = useState(true);

  const selectLanguage = (masterLanguages) => {
    let selectedLang;
    const seachParamLang = searchParams.get('lang');
    if (masterLanguages.includes(seachParamLang)) {
      selectedLang = seachParamLang;
    }
    try {
      if (selectedLang) {
        localStorage.setItem('lang', selectedLang);
      } else {
        const locallyStoredLang = localStorage.getItem('lang');
        if (masterLanguages.includes(locallyStoredLang)) selectedLang = locallyStoredLang;
        else selectedLang = masterLanguages?.[0] ?? 'en';
      }
    } catch (error) {
      console.log('LS Unavailable');
      selectedLang = masterLanguages?.[0] ?? 'en';
    } finally {
      i18n.changeLanguage(selectedLang);
    }
  };

  const fetchUser = async () => {
    try {
      const {
        data: { data = {} }
      } = await getUserProfile();

      setProfileData({ ...data, loaded: true });
      console.log('Profile Data:', data);
      changeFavicon(process.env.REACT_APP_AWS_URL + data?.masterData?.favicon);
      selectLanguage(data?.masterData?.languages);
      scrollToFragment(); // This is for first time loading only
    } catch (error) {
      console.error('Error fetching user', error);
      navigate('/');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      const existingProfileApiToken =
        profileApi.defaults.headers.common?.['Authorization']?.split(' ')?.[1];
      if (existingProfileApiToken) {
        fetchUser();
      } else {
        const token = localStorage.getItem('token');
        profileApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        fetchUser();
      }
    } catch (error) {
      console.log(error);
      console.log('LS Unavailable');
      navigate('/');
    }
  }, []);

  if (process.env.NODE_ENV !== 'development' && !isMobile)
    return <DeviceTypePopup orgPrefix={profile?.masterData?.translationPrefix} />;

  if (isLoading) return <Loader />;

  return (
    <div className="w-full">
      <Header orgPrefix={profile?.masterData?.translationPrefix} />
      <div className="p-10 mt-28">
        <Outlet />
      </div>
    </div>
  );
}

// Used for regional videos pages - Not sure about their use yet
function ProtectedRegion({ role = null, children }) {
  let token = localStorage.getItem('regionToken');

  if (!token) {
    return <Navigate to="/region/login" replace />;
  }

  return children;
}
function TokenExistence({ children }) {
  let token = localStorage.getItem('regionToken');
  if (token) {
    return <Navigate to="/region/welcome-region" replace />;
  }
  return children;
}
