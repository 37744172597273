import React from 'react';
import { useTranslation } from 'react-i18next';

import { CloseIcon } from '../icons';
import { getTranslation } from '../../utils';
import Button from '../ui/button';

export default function UnsavedChanges({ close, orgPrefix, saveCallback }) {
  const { t } = useTranslation();

  return (
    <div className="fixed bg-white shadow-xl sm-popup helpline p-8 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[80%]">
      <div className="flex w-full justify-end mb-4 text-black" onClick={close}>
        <CloseIcon />
      </div>
      <div className="content flex flex-col justify-center !w-[100%] !h-[100%] !mx-0 !my-0">
        <div className="text-center">
          <img src="/images/icons/danger.jpg" className="w-18 mx-auto mb-6" />
        </div>
        <div className="px-4">
          <h2
            className="heading mb-3"
            dangerouslySetInnerHTML={{
              __html: getTranslation(t, 'unsavedChanges.heading', orgPrefix)
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: getTranslation(t, 'unsavedChanges.description', orgPrefix)
            }}
          />

          <div className="flex gap-4 items-center justify-center mt-4">
            <Button.ButtonRounded
              className="btn btn-primary"
              onClick={() => saveCallback && saveCallback()}>
              {getTranslation(t, `unsavedChanges.save`, orgPrefix)}
            </Button.ButtonRounded>
            <Button.ButtonOutlined className="btn btn-primary" handler={close}>
              {getTranslation(t, `unsavedChanges.cancel`, orgPrefix)}
            </Button.ButtonOutlined>
          </div>
        </div>
      </div>
    </div>
  );
}
