import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { RegionContext } from '../../contexts/regionContext';
export default function AdminApp() {
  const [regionData, setRegionData] = useState({
    // Variables  here
    loaded: false
  });

  return (
    <RegionContext.Provider className="region" value={[regionData, setRegionData]}>
      <Outlet />
    </RegionContext.Provider>
  );
}
