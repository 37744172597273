export const VideosData = {
  'video-1': {
    questionire: {
      mainSection: {
        heading: 'LUNDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-2': {
    questionire: {
      mainSection: {
        heading: 'LUNDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-3': {
    questionire: {
      mainSection: {
        heading: 'LUNDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-4': {
    questionire: {
      mainSection: {
        heading: 'MARDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-5': {
    questionire: {
      mainSection: {
        heading: 'MARDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-6': {
    questionire: {
      mainSection: {
        heading: 'MARDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-7': {
    questionire: {
      mainSection: {
        heading: 'MERCREDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-8': {
    questionire: {
      mainSection: {
        heading: 'MERCREDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-9': {
    questionire: {
      mainSection: {
        heading: 'MERCREDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-10': {
    questionire: {
      mainSection: {
        heading: 'JEUDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-11': {
    questionire: {
      mainSection: {
        heading: 'JEUDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-12': {
    questionire: {
      mainSection: {
        heading: 'JEUDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-13': {
    questionire: {
      mainSection: {
        heading: 'VENDREDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-14': {
    questionire: {
      mainSection: {
        heading: 'VENDREDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-15': {
    questionire: {
      mainSection: {
        heading: 'VENDREDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-16': {
    questionire: {
      mainSection: {
        heading: 'SAMEDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-17': {
    questionire: {
      mainSection: {
        heading: 'SAMEDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-18': {
    questionire: {
      mainSection: {
        heading: 'SAMEDI',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-19': {
    questionire: {
      mainSection: {
        heading: 'DIMANCHE',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-20': {
    questionire: {
      mainSection: {
        heading: 'DIMANCHE',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  },
  'video-21': {
    questionire: {
      mainSection: {
        heading: 'DIMANCHE',
        description: '',
        options: [
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'XYZ',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/167672725743020230218_143242.mp4'
          }
        ]
      },
      secondSection: {
        heading: 'Le thème de ta vidéo...',
        description: `est déterminé par la personne responsable de votre région. <br />
        C’est elle qui discute avec vous de quoi vous allez parler et  <br />
        ce que vous allez montrer.`,
        note: (
          <>
            <span className="font-bold">Important :</span> Enregistrez ou téléchargez une vidéo en
            format portrait ! <br /> Si la prise est en format paysage, elle sera recadrée en format
            portrait.
          </>
        )
      }
    },
    popup: {
      title: '',
      description: ``
    },
    nextPage: '/setup-complete'
  }
};
