import React, { useContext } from 'react';
import { ProfileContext } from '../../../contexts/ProfileContext';

export default function ButtonOutlined({ className, children, handler, disabled }) {
  const [profile] = useContext(ProfileContext);
  const { masterData: { customConfigurations: { colors, buttonRadius } = {} } = {} } = profile;

  return (
    <button
      className={`px-4 py-2 border-2 ${className}`}
      style={{ borderColor: colors?.primary, borderRadius: buttonRadius, color: colors?.primary }}
      disabled={disabled}
      onClick={() => handler()}>
      {children}
    </button>
  );
}
