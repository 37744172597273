import Select, { components } from 'react-select';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
export default function InputMultiSelect({
  label,
  error,
  touched,
  labelClassName,
  options = [],
  onReset,
  disabled,
  ...props
}) {
  if (!options?.length) return null;

  return (
    <div className="">
      {label && <label className={`${labelClassName}`}>{label}</label>}

      {onReset && props.value && (
        <ArrowPathIcon
          className="w-4 inline-block ml-2 cursor-pointer"
          title="reset"
          onClick={onReset}
        />
      )}

      <Select
        styles={{
          control: (base) => ({
            ...base,
            background: '#f6f6f6',
            border: 0,
            boxShadow: 'none',
            padding: '0.5rem 0.5rem',
            borderRadius: '1rem',
            fontSize: '1.25rem',
            color: 'black',
            outline: 'none'
          }),
          option: (base) => ({
            ...base,
            color: 'black',
            fontSize: '1.25rem',
            padding: '0.5rem 0.5rem',
            cursor: 'pointer',
            backgroundColor: '#f6f6f6'
          })
        }}
        hideSelectedOptions={false}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'gray',
            primary: 'black'
          }
        })}
        isMulti={true}
        className="mb-4"
        options={options}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        components={{ Option: CheckboxOption }}
        isDisabled={disabled}
        {...props}
      />
      {error && touched && <p className="text text-red-500">{error}</p>}
    </div>
  );
}
const CheckboxOption = ({ children, isSelected, ...rest }) => {
  return (
    <components.Option {...rest}>
      <div className="flex justify-between cutom-checkbox">
        <span>{children}</span>
        <input type="checkbox" defaultChecked={isSelected} />
      </div>
    </components.Option>
  );
};
