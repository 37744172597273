const bestApprentice = [
  {
    label: 'Oui',
    value: 'oui'
  },
  {
    label: 'Non',
    value: 'no'
  }
];

const genderOptions = [
  {
    label: 'Masculin',
    value: 'masculin'
  },
  {
    label: 'Féminin',
    value: 'féminin'
  }
];

const ouvrierOption = [
  {
    lable: 'Yes',
    value: 'yes'
  },
  {
    label: 'No',
    value: 'no'
  }
];

const fullRangeGroup = Array.from({ length: 100 }, (_, index) => index + 1).map((item) => ({
  label: item.toString(),
  value: item
}));

export { bestApprentice, genderOptions, ouvrierOption, fullRangeGroup };
