import React, { useState, useEffect, createRef } from 'react';

import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { FaLongArrowAltRight, FaLongArrowAltLeft } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../../utils';

const Trimmer = ({
  videoDuration,
  videoURL,
  reRecordVideo,
  onUploadVideo,
  setTrimPoints,
  fromUpload,
  isRegional = false
}) => {
  const { t } = useTranslation();
  const [rangeValue, setRangeValue] = useState([0, videoDuration]);

  const trimmedVideoRef = createRef();

  const goBack = () => {
    reRecordVideo();
  };

  useEffect(() => {
    setTrimPoints(rangeValue);
    if (!trimmedVideoRef.current || trimmedVideoRef.src) return;

    trimmedVideoRef.current.currentTime = rangeValue[0];
    trimmedVideoRef.current.play();
  }, [rangeValue]);

  return (
    <div className="video-trimmer">
      <div className="video-container">
        <video
          ref={trimmedVideoRef}
          src={videoURL}
          start={rangeValue[0]}
          end={rangeValue[1]}
          className="video-player"
          autoPlay
          playsInline
          loop
          controls
          onTimeUpdate={(e) => {
            if (e.target.currentTime >= rangeValue[1]) {
              e.target.currentTime = rangeValue[0];
              e.target.play();
            }
            if (e.target.currentTime < rangeValue[0] && rangeValue[0] !== 0) {
              e.target.currentTime = rangeValue[0];
              e.target.play();
            }
          }}
        />
        {/* <div className="btn-player-container">
          <button className="btn btn-player">
            <FaRegPlayCircle className="icon" />
          </button>
        </div> */}
      </div>
      <RangeSlider
        min={0}
        max={videoDuration}
        step={videoDuration / 100}
        value={rangeValue}
        onInput={(value) => {
          setRangeValue(value);
          setTrimPoints(value);
        }}
        className="range-slider my-4"
      />
      {isRegional ? (
        fromUpload ? (
          <>
            <button
              className="btn btn-primary flex items-center mx-auto mb-4"
              onClick={onUploadVideo}>
              Télécharger
              <FaLongArrowAltRight className="icon ml-4" />
            </button>
            <button
              className="btn btn-outlined flex items-center mx-auto border-2 border-black"
              onClick={goBack}>
              <FaLongArrowAltLeft className="icon mr-4" />
              <p>
                Remplacer la vidéo <br />
                par une autre
              </p>
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-outlined flex items-center mx-auto border-2 border-black mb-4"
              onClick={goBack}>
              <FaLongArrowAltLeft className="icon mr-4" />
              <p>Réenregistrer la vidéo</p>
            </button>
            <button className="btn btn-primary flex items-center mx-auto" onClick={onUploadVideo}>
              Télécharger
              <FaLongArrowAltRight className="icon ml-4" />
            </button>
          </>
        )
      ) : (
        <>
          {/* <button
            className="btn theme-buttonOutline flex items-center mx-auto mb-4"
            onClick={goBack}>
            <FaLongArrowAltLeft className="icon mr-4" />
            {getTranslation(t,`videoTrim.record`, orgPrefix)}
          </button>
          <button className="btn btn-primary flex items-center mx-auto" onClick={onUploadVideo}>
            {getTranslation(t,`videoTrim.download`, orgPrefix)}
            <FaLongArrowAltRight className="icon ml-4" />
          </button> */}
        </>
      )}
    </div>
  );
};

export default Trimmer;
