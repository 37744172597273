import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../ui/button';

export default function RegionUploadSuccess({ goToNextPage }) {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const onContinue = () => {
    navigate('/region/support');
  };

  const onSaveAndExit = () => {
    localStorage.removeItem('regionToken');
    localStorage.removeItem('lang');
    localStorage.removeItem('theme');
    navigate('/region/login');
  };

  const onNextUpload = () => {
    navigate('/region/video-region');
  };

  return (
    <div className="sm-popup helpline p-6" style={{ display: open ? 'block' : 'none' }}>
      <div className="content-container">
        <div className="content !px-10">
          <div className="text-center">
            <img src="/images/icons/thumbs-up.png" className="w-20 mx-auto mb-6" />
          </div>
          <h2 className="heading quickSand mb-20">Votre vidéo a été téléchargée.</h2>
          <div className="flex flex-col gap-4 mt-6 justify-center w-full px-7">
            {/* <Button.ButtonNext
              title="Je ne me sens pas à l’aise et ai besoin de support"
              className={'w-full mx-auto'}
              handler={onContinue}
            /> */}
            <Button.ButtonOutlined
              className={'btn-danger !rounded-lg quickSand mx-auto'}
              handler={onNextUpload}>
              ENREGISTRER UNE AUTRE VIDÉO !
            </Button.ButtonOutlined>
            <Button.ButtonOutlined
              className={'btn-danger !rounded-lg quickSand mx-auto'}
              handler={onSaveAndExit}>
              DÉCONNECTER !
            </Button.ButtonOutlined>
          </div>
        </div>
      </div>
    </div>
  );
}
