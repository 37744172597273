import React, { useContext, useState } from 'react';
import { HiDownload } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ProfileContext } from '../../contexts/ProfileContext';
import { generateProfilePDF } from '../../service';

import { CrossButton, Spinner } from '../common';
import Button from '../ui/button';

const AWS_URL = process.env.REACT_APP_AWS_URL;

export default function DownloadPDF({ closeModal, userId, role }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [pdfType, setPDFType] = useState('');
  const [profile, setProfile] = useContext(ProfileContext);
  const { type } = profile;

  const handlePDFDownload = async (pageSize) => {
    setPDFType(pageSize);
    if (!profile.isUpdatedForPDF) {
      downloadFile(AWS_URL + profile._id + '/' + pageSize + '.pdf');
      return;
    }

    try {
      setLoading(true);

      const { data: URL } = await generateProfilePDF({ id: profile._id });
      downloadFile(URL);
      setProfile({ ...profile, isUpdatedForPDF: false });
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setLoading(false);
      closeModal();
    }
  };

  const downloadFile = (url) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf'
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        const slpitArray = url.split('/');
        const name = slpitArray[slpitArray.length - 1];

        // Create blob link to download
        const finalURL = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = finalURL;
        link.setAttribute('download', name);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setLoading(false);
        closeModal();
      });
  };

  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="relative bg-white shadow-md flex">
        <div className="relative max-w-xs mx-auto flex items-center flex-col text-center  py-20 px-8">
          <div className="absolute top-0 left-0">
            <CrossButton onClick={closeModal} />
          </div>
          <div className="text-center mt-4">
            <img src={`./images/icons/${type}-email-sent.png`} className="w-32 mx-auto mb-6" />
          </div>
          <h2 className="font-bold mb-2">{t('pdfPopup.title')}</h2>
          <Button.ButtonOutlined
            disabled={loading}
            handler={() => handlePDFDownload('A4')}
            className="flex justify-center items-center mt-5">
            A3/A4/A5{' '}
            {loading && pdfType === 'A4' ? (
              <Spinner color="black" isSubmitting={true} />
            ) : (
              <HiDownload className="ml-1" />
            )}
          </Button.ButtonOutlined>
        </div>
      </div>
    </div>
  );
}
