import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../ui/button/';
import { FaRedo } from 'react-icons/fa';
import { getTranslation } from '../../utils';

export default function VideoUploadedPopup({ goToNextPage, orgPrefix }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onContinue = () => {
    goToNextPage();
  };

  return (
    <div className="fixed top-0 left-0 flex items-center justify-center h-screen w-full z-50 bg-white/20 backdrop-blur-sm">
      <div className="w-2/3 h-auto px-5 py-5 bg-white rounded shadow-lg flex flex-col justify-center items-center">
        <h2 className="w-full text-center font-bold text-2xl">
          {getTranslation(t, `singleVideoUploaded.uploadCompleted`, orgPrefix)}
        </h2>

        <div className="flex flex-col gap-4 mt-5">
          <Button.ButtonRounded className={'w-fit mx-auto'} onClick={onContinue}>
            {getTranslation(t, `singleVideoUploaded.continue`, orgPrefix)}
          </Button.ButtonRounded>
          <Button.ButtonOutlined
            className={'flex gap-2 items-center w-fit mx-auto'}
            handler={() => navigate('/my-profile')}>
            <FaRedo /> {getTranslation(t, `singleVideoUploaded.redo`, orgPrefix)}
          </Button.ButtonOutlined>
        </div>
      </div>
    </div>
  );
}
