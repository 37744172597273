import { ArrowPathIcon } from '@heroicons/react/24/outline';

import Input from './input';
import InputSelect from './InputSelect';

export default function InputGroupSelectRange({
  label,
  year,
  labelClassName,
  range,
  onReset,
  disabled
}) {
  if (!range?.options?.length) return null;

  return (
    <div>
      {label && <label className={`text-lg font-semibold ${labelClassName}`}>{label}</label>}
      {onReset && (range.value || year.value) && (
        <ArrowPathIcon
          className="w-4 inline-block ml-2 cursor-pointer"
          title="reset"
          onClick={onReset}
        />
      )}
      <div className="flex flex-row gap-2">
        <div className="w-1/2">{year?.value && <Input {...year} disabled={disabled} />}</div>
        <div className="w-1/2">
          {/* {range.label && <label className="mr-2">{range.label}</label>} */}
          <InputSelect
            className="rounded w-full"
            name={range.name}
            placeholder={range.label ?? ''}
            value={range.value}
            touched={range.touched}
            onBlur={range.handleBlur}
            error={range.error}
            options={range.options}
            onChange={(val) => {
              range.setFieldValue(range.name, val, true);
            }}
            disabled={disabled}
          />
        </div>
        {/* {range.error && range.touched && <p className="text text-red-500">{range.error}</p>} */}
      </div>
    </div>
  );
}
