import ButtonNext from './ButtonNext';
import ButtonOutlined from './ButtonOutlined';
import ButtonRounded from './ButtonRounded';
const Button = {
  ButtonNext,
  ButtonOutlined,
  ButtonRounded
};

export default Button;
