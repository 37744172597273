import React, { useState } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import ProgressBar from '../ui/ProgressBar';
import Popups from '../popups';
import { useNavigate } from 'react-router-dom';
import { videoSupportContact } from '../../service';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../utils';

export default function VideoUploader({
  percentage,
  resetVideoPage,
  nextPage,
  success = false,
  fail = false,
  finalVideo = false,
  retryUpload,
  isCompetitor = false,
  orgPrefix
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showVideoHelp, setVideoHelp] = useState(false);

  const sendProfileCompletionEmail = async () => {
    try {
      await videoSupportContact({
        isCompletion: true
      });
    } catch (e) {
      console.log('error', e);
    }
  };

  const gotToNextPage = () => {
    resetVideoPage();
    navigate(nextPage);
  };

  const handleFinish = async () => {
    await sendProfileCompletionEmail();
    navigate('/my-profile');
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full bg-white text-center px-6 flex flex-col justify-center items-center h-screen gap-8">
        <div className="icon-heading">
          <AiOutlineClockCircle className="text-6xl mx-auto" />
          <h1 className="text-2xl font-bold">
            {getTranslation(t, 'videoUploader.uploaded', orgPrefix)}
          </h1>
        </div>
        <div className="h-2 w-full bg-gray-300 rounded-full mt-32">
          <ProgressBar progressPercentage={percentage} />
          <p className="my-2">
            {percentage.toFixed(2)}% {getTranslation(t, 'videoUploader.complete', orgPrefix)}
          </p>
        </div>
      </div>

      {success && !finalVideo && !showVideoHelp && (
        <Popups.VideoUploadedPopup
          retryUpload={retryUpload}
          goToNextPage={gotToNextPage}
          orgPrefix={orgPrefix}
        />
      )}

      {success && finalVideo && (
        <Popups.ProfileComplete
          title={getTranslation(t, `videoUploader.finalVideoPopup.title`, orgPrefix)}
          description={getTranslation(t, `videoUploader.finalVideoPopup.description`, orgPrefix)}
          finishProfile={handleFinish}
          buttonText={getTranslation(t, `videoUploader.finalVideoPopup.button`, orgPrefix)}
        />
      )}
      {fail && <Popups.OnVideoUploadFailed retryUpload={retryUpload} orgPrefix={orgPrefix} />}
      {showVideoHelp && <Popups.VideoSupport />}
    </>
  );
}
