import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCheckCircle, FaCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { findIndex, isEmpty } from 'lodash';

import { Input } from '../../components';
import Buttton from '../../components/ui/button/';
import { videoHelpSchema } from '../../utils/schema';
import { videoSupportContact, getStrengths, linkStrengthToVideo } from '../../service';
import { ProfileContext } from '../../contexts/ProfileContext';
import { useTranslation } from 'react-i18next';

export default function VideoStrengthSelection({ onNext }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [profile, setProfile] = useContext(ProfileContext);

  const [myStrenghts, setMyStrenghts] = useState([]);
  const [selectedStrengths, setSelectedStrengths] = useState({});
  const [videoIndex, setVideoIndex] = useState(-1);
  const [error, setError] = useState(false);

  const handleSubmit = async (values) => {
    try {
      const resp = await videoSupportContact(values);
      toast.success("Votre demande a été envoyée à l'administrateur");
      handleNext();
    } catch (e) {
      console.log('error', e);
      toast.error(e.message);
    }
  };

  const handleSelect = (listItem) => {
    setError(selectedStrengths._id === listItem._id);
    setSelectedStrengths(selectedStrengths._id === listItem._id ? {} : listItem);
  };

  const getPageData = async () => {
    try {
      const {
        data: { data: strengthData }
      } = await getStrengths(profile?.masterOrganization);
      let filterdstrengths = strengthData
        .filter(({ _id }) => profile.strengths.includes(_id))
        .map(({ _id, ...item }) => ({
          name: item[`name_${i18n.language}`],
          _id
        }));

      setMyStrenghts(filterdstrengths);
    } catch (e) {
      console.log('ERROR', e);
    }
  };

  const handleNext = async () => {
    try {
      const isError = isEmpty(selectedStrengths);
      setError(isError);
      if (isError) return;

      await linkStrengthToVideo({ strengthId: selectedStrengths._id });
      let tempProfile = { ...profile };

      if (tempProfile.videos?.length) {
        tempProfile.videos.videos[videoIndex].strengthId = selectedStrengths;

        setProfile({ ...tempProfile });
      }
      if (onNext) {
        onNext();
      } else {
        navigate('/video-2');
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const formik = useFormik({
    initialValues: { tel: '' },
    validationSchema: videoHelpSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    if (!myStrenghts) return;

    let index = findIndex(
      profile.videos?.videos,
      ({ videoID: tVideoID }) => parseInt(tVideoID) === 1
    );
    setVideoIndex(index);
    if (index >= 0) {
      const { name_fr, _id } = profile.videos.videos[index].strengthId || {};

      if (profile.strengths.includes(_id)) setSelectedStrengths({ name: name_fr, _id });
    }
  }, [profile, myStrenghts]);

  useEffect(() => {
    getPageData();
  }, []);

  return (
    <div className="block">
      <div className="relative  py-10 px-3">
        {profile.type !== 'ch' && (
          <div>
            <p className="text-xl">
              <strong>{t(`videoPage.videoSupport.title`)}</strong>
            </p>
            <p className="mt-5">{t(`videoPage.videoSupport.description`)}</p>
            <form onSubmit={formik.handleSubmit}>
              <Input
                className="my-3"
                name="tel"
                type="tel"
                label={t(`videoPage.videoSupport.textLabel`)}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.tel}
                error={formik.errors.tel}
                touched={formik.touched.tel}
                isDark
              />
              <Buttton.ButtonRounded type="submit" className="mx-auto">
                {t(`videoPage.videoSupport.support`)}
              </Buttton.ButtonRounded>
            </form>
          </div>
        )}
        <div className="mt-16">
          {profile.type !== 'ch' && (
            <div>
              <p className="text-xl text-center">
                <strong>{t(`videoPage.videoSupport.title2`)}</strong>
              </p>
              <p className="mt-5 text-center">{t(`videoPage.videoSupport.description2`)}</p>
            </div>
          )}

          <p className="text-xl text-center">
            <strong>{t(`videoPage.videoSupport.selectTitle`)}</strong>
          </p>
          <div className="items-list-container">
            <ul className="list-selector mt-10">
              {myStrenghts.map((item) => (
                <li
                  key={item._id}
                  className="list-item cursor-pointer"
                  onClick={() => handleSelect(item)}>
                  <div className="flex justify-between items-center">
                    <span className="mr-2">{item.name}</span>
                    <div>
                      {item._id === selectedStrengths._id ? (
                        <FaCheckCircle className="icon active" size={25} />
                      ) : (
                        <FaCircle className="icon" size={25} />
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          {error && <p className="text-red-500 text-center">{t(`videoPage.videoSupport.error`)}</p>}

          <Buttton.ButtonRounded type="button" className="mx-auto mt-5" onClick={handleNext}>
            {t(`videoPage.videoSupport.continue`)}
          </Buttton.ButtonRounded>
        </div>
      </div>
    </div>
  );
}
