import React from 'react';
import Button from '../../ui/button';
import { RxCross1 } from 'react-icons/rx';

export default function InfoPopup({ data, show, setShow }) {
  return (
    <div className="video-info-popup">
      {show ? (
        <div className="popup">
          {/* <div className="cta">
            <Button.FullRoundedButton className="btn-times" onClick={() => setShow(!show)}>
              <RxCross1 />
            </Button.FullRoundedButton>
          </div> */}
          <div className="content-container">
            <div className="content">
              <h2 className="text-white font-bold mb-2 text-center">{data.title}</h2>
              <p
                className="description text-center"
                dangerouslySetInnerHTML={{ __html: data.description }}></p>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
