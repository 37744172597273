import React from 'react';

import HelpLinePopupItem from './HelpLinePopupItem';
import { CrossButton } from '../common';

export default function HelpLinePopup({ icon, title, description, closeModal }) {
  return (
    <div className="fixed top-0 left-0 flex items-center justify-center h-screen w-full z-50 bg-white/20 backdrop-blur-sm">
      <div className="w-2/3 h-auto px-5 py-5 bg-white rounded shadow-lg flex justify-center items-center">
        {closeModal && (
          <CrossButton
            onClick={closeModal}
            className={`bg-gray-500 p-4 rounded-full text-white z-20 absolute top-[10px] left-[10px]`}
          />
        )}
        <div className="flex w-full items-center justify-center flex-col">
          {icon && typeof icon !== 'string' && <span className="w-1/3 mb-3">{icon}</span>}
          {icon && typeof icon === 'string' && (
            <img src={`/images/icons/${icon}.png`} alt="icon" className="w-1/3 mb-3" />
          )}
          <HelpLinePopupItem title={title} description={description} />
        </div>
      </div>
    </div>
  );
}
