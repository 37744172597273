import React, { useContext } from 'react';
import { ProfileContext } from '../../../contexts/ProfileContext';
import { FaArrowRight } from 'react-icons/fa';
import { Spinner } from '../../common';

export default function ButtonNext({ title, type, handler, className, loading = false, disabled }) {
  const [profile] = useContext(ProfileContext);
  const { masterData: { customConfigurations: { colors, buttonRadius } = {} } = {} } = profile;

  return (
    <button
      type={type}
      className={`btn btn-next p-4 flex flex-row gap-2 items-center justify-center text-white ${className} disabled:opacity-60`}
      style={{ backgroundColor: colors?.primary, borderRadius: buttonRadius }}
      onClick={handler}
      disabled={loading || disabled}>
      <span dangerouslySetInnerHTML={{ __html: title }} />
      <span className="icon-container ml-4">
        <FaArrowRight className="icon" />
      </span>
      <Spinner isSubmitting={loading} />
    </button>
  );
}

ButtonNext.defaultProps = {
  className: '',
  type: ''
};
