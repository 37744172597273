/**
 * 📅 Last Modified: 04 Feb, 2023
 */
import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { Input } from '../../components';
import Button from '../../components/ui/button';
import HelpLinePopup from '../../components/popups/HelpLinePopup';
import DeviceTypePopup from '../../components/popups/DeviceTypePopup';
import { LoginSchema } from '../../utils/schema';
import { regionalLogin } from '../../service';
import { WksMFLogo } from '../../assets/logos';

export default function RegionLogin() {
  const navigate = useNavigate();

  const [emailSent, setEmailStatus] = useState(false);

  const submitHandler = async (values, {}) => {
    try {
      const response = await regionalLogin(values);

      if (response?.status === 200) {
        setEmailStatus(true);
      } else toast.error('Your login was unsuccessful');
    } catch (error) {
      const message = error.response.data.message || 'Something went wrong';
      console.log('ERROR', error.response.data.message);
      toast.error(message);
    } finally {
      formik.setSubmitting(false);
    }
  };

  const resendEmail = async () => {
    try {
      const response = await regionalLogin(formik.values);

      if (response?.status === 200) {
        toast.success('Email Sent again');
        closeModal();
      } else toast.error('Your login was unsuccessful');
    } catch (error) {
      console.log('ERROR', error);
      toast.error(error.message);
    } finally {
      formik.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: LoginSchema,
    onSubmit: submitHandler
  });

  useEffect(() => {
    localStorage.setItem('lang', 'fr');
    localStorage.setItem('theme', 'fr');
    let token = localStorage.getItem('token');
    if (token) navigate('/my-profile');
  }, []);

  const closeModal = () => {
    formik.resetForm();
    setEmailStatus(false);
  };

  return (
    <>
      <section className="" data-testid="login-form">
        {emailSent ? (
          <HelpLinePopup
            title={'Un e-mail t’a été envoyé !'}
            description={'Vérifie dans ta boîte à lettre et le dossier des mails indésirables'}
            icon={'fr-email-sent'}
            resendEmail={resendEmail}
            closeModal={closeModal}
            data-testid="email-sent-popup"
          />
        ) : (
          <LoginForm formik={formik} />
        )}
        {process.env.NODE_ENV !== 'development' && !isMobile && <DeviceTypePopup />}
      </section>
      {/* <Popups.HelpLinePopup/> */}
    </>
  );
}

const LoginForm = ({ formik }) => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full hidden md:flex py-10 px-5 md:py-0 justify-end md:w-7/12 bg-cblue-500 md:min-h-screen relative">
        <WksMFLogo className="md:absolute md:bottom-10 md:right-10 max-w-[80%]" />
      </div>
      <div className="w-auto md:w-5/12 md:mx-10 relative">
        <div className="md:py-24">
          <div className="px-4 py-8 mx-2 bg-cblue-500 md:bg-white">
            <h1 className="text-5xl font-bold text-white md:text-cblue-500 underline decoration-slate-500 decoration-2 underline-offset-8	md:decoration-0 md:decoration-white">
              CONNEXION
            </h1>
          </div>
          <form className="mt-24 mx-10" onSubmit={formik.handleSubmit}>
            <Input
              className="mb-3"
              name="email"
              placeholder="email@gmail.com"
              data-testid="email-field"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.errors.email}
              touched={formik.touched.email}
            />
            <div className="border-b mt-14 border-black" />

            <div className="items-center md:absolute mt-10 md:mt-0 bottom-10 mx-auto left-0 right-0 text-center">
              <Button.ButtonRounded
                loading={formik.isSubmitting}
                className="mx-auto"
                type="submit"
                data-testid="submit-button">
                CONNEXION
              </Button.ButtonRounded>
              <p className="mt-6">Si nécessaire, contacte </p>
              <a className="btn mb-4 block underline" href="mailto:hugodelouche2@gmail.com">
                hugodelouche2@gmail.com
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
