const options = [
  { value: '1', label: 'Administration et gestion des Systèmes et des Réseaux' },
  { value: '2', label: 'Art floral' },
  { value: '3', label: 'Assemblage cycle' },
  { value: '4', label: "Assemblage d'ordinateurs" },
  { value: '5', label: 'Boulangerie' },
  { value: '6', label: 'Broderie' },
  { value: '7', label: 'Carrelage' },
  { value: '8', label: 'Coiffure' },
  { value: '9', label: 'Conception assistée par ordinateur (CAD)' },
  { value: '10', label: "Conception d'Affiches" },
  { value: '11', label: 'Conception graphique de documents' },
  { value: '12', label: 'Couture (avancé)' },
  { value: '13', label: 'Couture (basique)' },
  { value: '14', label: 'Création de pages Web' },
  { value: '15', label: 'Crochet' },
  { value: '16', label: 'Cuisine (épreuve en duo)' },
  { value: '17', label: 'Cuisine (individuel)' },
  { value: '18', label: 'Décoration de gâteaux' },
  { value: '19', label: 'Ebéniste (basique)' },
  { value: '20', label: 'Ebénisterie (avancé)' },
  { value: '21', label: 'Electronique (avancé)' },
  { value: '22', label: 'Gestion et Traitement de données' },
  { value: '23', label: 'Illustration' },
  { value: '24', label: 'Installation Electrique' },
  { value: '25', label: 'Jardinier Paysagiste (individuel)' },
  { value: '26', label: 'Joaillerie' },
  { value: '27', label: 'Journalisme Multimédia' },
  { value: '28', label: 'Manucure' },
  { value: '29', label: 'Massage' },
  { value: '30', label: 'Patisserie et confiserie' },
  { value: '31', label: 'Peinture et décoration' },
  { value: '32', label: 'Peinture et recyclage créatif' },
  { value: '33', label: 'Peinture sur soie' },
  { value: '34', label: 'Photographie Reportage' },
  { value: '35', label: 'Photographie Studio' },
  { value: '36', label: 'Poterie' },
  { value: '37', label: 'Programmation informatique' },
  { value: '38', label: 'Propreté' },
  { value: '39', label: 'Sculpture fruits et légumes' },
  { value: '40', label: 'Sculpture sur bois' },
  { value: '41', label: 'Service en salle' },
  { value: '42', label: 'Soudage' },
  { value: '43', label: 'Tailleur' },
  { value: '44', label: 'Technicien Dentaire' },
  { value: '45', label: 'Traitement de texte' },
  { value: '46', label: 'Vannerie' }
];

export default options;
