import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { getTranslation } from '../../utils';

import { ProfileContext } from '../../contexts/ProfileContext';

export default function Input({
  label,
  placeholder,
  labelClassName = '',
  className,
  error,
  touched,
  isDark = false,
  inputClassName = '',
  onReset,
  ...props
}) {
  const { t } = useTranslation();

  const [profile] = useContext(ProfileContext);

  const { masterData: { translationPrefix } = {} } = profile;

  return (
    <div className={`${className}`}>
      {label && <label className={`block text-lg ${labelClassName}`}>{label}</label>}
      {onReset && props.value && (
        <ArrowPathIcon
          className="w-4 inline-block ml-2 cursor-pointer"
          title="reset"
          onClick={onReset}
        />
      )}
      <input
        className={`border-none outline-none px-5 py-4 rounded-2xl text-xl bg-grey-100 text-black w-full ${inputClassName}`}
        placeholder={placeholder}
        {...props}
      />
      {error && touched && (
        <p className="text-red-500 text-base font-normal" data-testid="error-text">
          {getTranslation(t, `form.fields.${error}Error`, translationPrefix)}
        </p>
      )}
    </div>
  );
}

Input.defaultProps = {
  className: ''
};
