import React from 'react';
import { useParams } from 'react-router-dom';

import FullPagePopup from '../components/popups/fullPagePopup';

export default function ExampleVideo() {
  const { id } = useParams();

  return (
    <FullPagePopup closeLink={-1}>
      <div
        style={{
          padding: '173.25% 0px 0px',
          position: 'relative',
          borderRadius: '5px',
          overflow: 'hidden'
        }}>
        <iframe
          src={`https://player.vimeo.com/video/${id}?autoplay=1`}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </div>
    </FullPagePopup>
  );
}
