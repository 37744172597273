import React from 'react';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../utils';

export default function DeviceTypePopup({ orgPrefix }) {
  const { t } = useTranslation();

  return (
    <div className="absolute inset-0">
      <div className="bg-white h-screen w-screen shadow-md">
        <div className="flex items-center justify-center">
          <div className="relative max-w-xs mx-auto flex items-center flex-col text-center h-screen justify-center">
            <div className="text-center">
              <img src="/images/icons/device.png" className="w-32 mx-auto mb-6" />
            </div>
            <h2 className="font-bold mb-2">{getTranslation(t, 'deviceType.title', orgPrefix)}</h2>
            <p>{getTranslation(t, 'deviceType.description', orgPrefix)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
