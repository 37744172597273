import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import GenericField from './genericField';
import Button from '../ui/button';

import { getTranslation } from '../../utils';

import { fullRangeGroup, bestApprentice, genderOptions } from '../../data/professionOptions';

import AbilympicsProfessions from '../../data/AbilympicsProfession';

const GenericForm = ({
  editableForm,
  profile,
  fieldKeys,
  regions,
  professions,
  listSwissRegions,
  listProfessionalAssoc,
  listSwissFunction,
  listSwissJobTitle,
  listVocationalSchool,
  orgPrefix,
  formik,
  requiredFieldMissing,
  submitting,
  initialValues,
  setInitialValues
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!profile?.loaded) return;

    const objBestApprentice = bestApprentice.filter(
      (item) => item.value === profile.bestApprentice
    );
    const objbestWorker = bestApprentice.filter((item) => item.value === profile.bestWorker);
    const objgender = genderOptions.filter((item) => item.value === profile.gender);
    const ObjnatFinalsRank = fullRangeGroup.filter(
      (item) => item.value === parseInt(profile.natFinalsRank)
    );
    const ObjworldSkillsRank = fullRangeGroup.filter(
      (item) => item.value === parseInt(profile.worldSkillsRank)
    );
    const objnatFinalsRegion = regions.filter((item) => item.value === profile.natFinalsRegion);

    const ObjeuroSkillsRank = fullRangeGroup.filter(
      (item) => item.value === parseInt(profile.euroSkillsRank)
    );

    let objworldSkillsProfession = professions.filter(
      (item) => item.value === profile.worldSkillsProfession
    );

    // ch champion form
    const objParticipationType = t('form.participationType.values', {
      returnObjects: true
    })?.filter((item) => item.value === profile.participationType);
    const objSwissRegion = listSwissRegions.filter((item) => item.value === profile.region);
    const objProfessionAssoc = listProfessionalAssoc.filter(
      (item) => item.value === profile.associationProfession
    );
    const objSwissFunction = listSwissFunction.filter((item) => {
      if (profile.functions.includes(item.value)) {
        return item;
      }
    });
    const objSwissJobTitle = listSwissJobTitle.filter(
      (item) => item.value === profile.specialJobTitle
    );
    const objSwissJobChamp = professions.filter((item) => item.value === profile.profession);
    const objVocationalSchool = listVocationalSchool.filter(
      (item) => item.value === profile.vocationSchool
    );
    const ObjeuroSwissYear = fullRangeGroup.filter(
      (item) => item.value === parseInt(profile.swissChampionYear)
    );
    const ObjeuroSwissRank = fullRangeGroup.filter(
      (item) => item.value === parseInt(profile.swissChampionRank)
    );

    // Abylimpics
    const objAbilympicsProfession = AbilympicsProfessions.filter(
      (item) => item.label === profile.abilympicsProfession
    );

    setInitialValues({
      ...initialValues,
      ...profile,
      trainingAndDiploma: initialValues?.trainingAndDiploma1,
      recentProfession: initialValues?.recentProfession1,
      bestApprentice: objBestApprentice?.[0],
      bestWorker: objbestWorker?.[0],
      worldSkillsProfession: objworldSkillsProfession?.[0],
      gender: objgender?.[0],
      natFinalsRank: ObjnatFinalsRank?.[0],
      worldSkillsRank: ObjworldSkillsRank?.[0],
      euroSkillsRank: ObjeuroSkillsRank?.[0],
      natFinalsRegion: objnatFinalsRegion?.[0],
      // ch champion form
      swissChampionYear: ObjeuroSwissYear?.[0],
      swissChampionRank: ObjeuroSwissRank?.[0],
      region: objSwissRegion?.[0],
      specialJobTitle: objSwissJobTitle?.[0],
      profession: objSwissJobChamp?.[0] || '',
      associationProfession: objProfessionAssoc?.[0],
      vocationSchool: objVocationalSchool?.[0],
      functions: objSwissFunction,
      participationType: objParticipationType?.[0],
      abilympicsProfession: objAbilympicsProfession?.[0],
      montherLanguage: profile.montherLanguage
        ? { value: profile.montherLanguage, label: profile?.montherLanguage?.toUpperCase() }
        : ''
    });
  }, [profile, regions, professions]);

  return (
    <form onSubmit={formik?.handleSubmit} className="w-full h-full">
      <div className="flex w-full items-center justify-between mb-8">
        <h1 className="text-2xl font-bold">{getTranslation(t, `form.title`, orgPrefix)}</h1>
        {editableForm && (
          <Button.ButtonRounded type="submit" loading={submitting}>
            {getTranslation(t, `form.submit`, orgPrefix)}
          </Button.ButtonRounded>
        )}
      </div>

      <div className="flex flex-col gap-4 h-[100%] overflow-y-auto pb-32">
        {fieldKeys.map(({ field: { key } = {} }, index) => (
          <GenericField
            key={index}
            index={index}
            languages={profile?.masterData?.languages}
            fieldKey={key}
            requiredFieldMissing={requiredFieldMissing.includes(key)}
            formik={formik}
            regions={regions}
            professions={professions}
            listSwissRegions={listSwissRegions}
            listProfessionalAssoc={listProfessionalAssoc}
            listSwissFunction={listSwissFunction}
            listSwissJobTitle={listSwissJobTitle}
            listVocationalSchool={listVocationalSchool}
            orgPrefix={orgPrefix}
            disabled={!editableForm}
          />
        ))}
      </div>
    </form>
  );
};

export default GenericForm;
