/**
 * 📅 Last Modified: 04 Feb, 2023
 */
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/ui/button';
import { useTranslation } from 'react-i18next';
import LanguageDropdown from '../../components/common/LanguageDropdown';
import { ProfileContext } from '../../contexts/ProfileContext';
import { updateUserPreferredLanguage } from '../../service';
import { getTranslation } from '../../utils';

export default function Splash() {
  const [profile] = useContext(ProfileContext);
  const { masterData: { translationPrefix, languages, logo } = {} } = profile;

  const [langOptions, setLangOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({
    label: 'DE',
    value: 'de'
  });
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleChange = async (e) => {
    setSelectedLanguage(e);

    try {
      localStorage.setItem('lang', e.value);
    } catch (error) {
      console.log('LS Unavailable');
    }

    i18n.changeLanguage(e.value);

    try {
      // store preferred language
      await updateUserPreferredLanguage({ preferredLanguage: e.value });
    } catch (error) {}
  };

  // useEffect(() => {
  //   async function updateLang() {
  //     try {
  //       await updateUserPreferredLanguage({ preferredLanguage: i18n.language });
  //     } catch (error) {}
  //   }
  //   updateLang();
  // }, []);

  useEffect(() => {
    if (languages.length > 0) {
      const langOptions = languages.map((lang) => {
        return { label: lang?.toUpperCase(), value: lang };
      });
      setLangOptions(langOptions);
    }
  }, [languages]);

  useEffect(() => {
    const currentLang = i18n.language;
    const changedLang = langOptions.filter((item) => {
      return item.value === currentLang;
    });
    setSelectedLanguage(changedLang);
  }, [langOptions, i18n.language]);

  return (
    <div className="h-[80vh] flex flex-col gap-4 items-center justify-center">
      <section className="text-center">
        <div className="flex justify-center items-center ">
          <div className="relative ">
            <div className="max-w-full w-72 h-72 overflow-hidden">
              <img
                src={process.env.REACT_APP_AWS_URL + logo}
                className="block w-full h-full object-cover object-center"
              />
            </div>
          </div>
        </div>

        <div className="px-16 my-6">
          <h1 className="text-2xl font-bold">
            {getTranslation(t, `splash.title`, translationPrefix)}
          </h1>
          <p
            className="text-xl"
            dangerouslySetInnerHTML={{
              __html: getTranslation(t, `splash.description`, translationPrefix)
            }}></p>
        </div>

        <div className="mb-4 w-28 mx-auto">
          <LanguageDropdown
            value={selectedLanguage}
            options={langOptions}
            onChange={handleChange}
          />
        </div>

        <Button.ButtonNext
          title={getTranslation(t, `splash.button`, translationPrefix)}
          className="my-10 mx-auto"
          handler={() => navigate('/my-profile')}
        />
      </section>
    </div>
  );
}
