import React, { useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import ListSelector from '../ui/ListSelector';
import { useTranslation } from 'react-i18next';
import { CrossButton } from '../common';
import { getTranslation } from '../../utils';

export default function OptionSelectorPopup({
  handler,
  listItems,
  listType,
  addListItem,
  removeListItem,
  selectedItems,
  maxItems = 3,
  orgPrefix
}) {
  const { t, i18n } = useTranslation();
  const [filteredListItems, setFilteredListItems] = useState([...listItems]);
  const hideHandler = () => {
    handler();
  };

  const filterListItems = (e) => {
    var value = e.target.value;
    if (value.length > 0) {
      var tempList = listItems.filter((item) => {
        if (item[`name_${i18n.language}`].toLowerCase().includes(value.toLowerCase())) {
          return true;
        }
        return false;
      });
      setFilteredListItems(tempList);
    } else {
      setFilteredListItems(listItems);
    }
  };

  return (
    <div className="popup absolute top-0 left-0 z-[60] w-full !bg-white">
      <div className="flex justify-end w-full">
        <CrossButton onClick={hideHandler} className={`p-4 rounded-full text-black z-20`} />
        {/* <p className="px-2 py-2 w-full ml-3 flex justify-center items-center rounded font-semibold text-gray-400">
          {t(`closeText`)}
        </p> */}
      </div>

      <div className="content-container h-full">
        <div className="content h-full">
          <div className="flex gap-4 w-full items-center mb-3">
            <div className="icon-container">
              <FaSearch className="icon" />
            </div>
            <input
              className="bg-gray-200 text-black rounded-full px-4 py-3 w-full"
              placeholder={getTranslation(t, 'searchListPlaceholder', orgPrefix)}
              onChange={filterListItems}
            />
          </div>
          <div className="items-list-container h-[90%] pb-32 overflow-y-auto">
            <ListSelector
              list={filteredListItems}
              listType={listType}
              addListItem={addListItem}
              removeListItem={removeListItem}
              selectedItems={selectedItems}
              maxItems={maxItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
