import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../../../contexts/ProfileContext';

export default function RedoScreen({ show, setVideoURL, onOkay, isRegional = false }) {
  const { t } = useTranslation();
  const [profile] = useContext(ProfileContext);
  const { type } = profile;

  if (!show) {
    return <></>;
  }
  return (
    <div className="popup">
      {isRegional ? (
        <div className="redo-screen">
          <div className="action">
            <h2 className="heading">Enregistrer encore une fois ?</h2>
            <button className="btn btn-action" onClick={() => setVideoURL('')}>
              Oui
            </button>
          </div>
          <div className="action">
            <h2 className="heading">Continuer pour éditer ?</h2>
            <button className="btn btn-action" onClick={onOkay}>
              Oui
            </button>
          </div>
        </div>
      ) : (
        <div className="redo-screen">
          <div className="action">
            <h2 className="heading">
              {type === 'ch' || type === 'fr'
                ? t(`videoPage.videoUpload.title1`)
                : t('videoPage.videoUpload.title1')}
            </h2>
            <button className="btn btn-action" onClick={() => setVideoURL('')}>
              {t(`videoPage.videoUpload.yes`)}
            </button>
          </div>
          <div className="action">
            <h2 className="heading">
              {type === 'ch' || type === 'fr'
                ? t(`videoPage.videoUpload.title2`)
                : t('videoPage.videoUpload.title2')}
            </h2>
            <button className="btn btn-action" onClick={onOkay}>
              {t(`videoPage.videoUpload.yes`)}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
