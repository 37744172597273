import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import Video from '../../components/video';
import { VideosData } from './data/videoData';
import { RegionContext } from '../../contexts/regionContext';
import { useParams } from 'react-router-dom';
import '../../styles/videoRecorder.scss';
import { getUploadingFileData, uploadRegionalVideo, uploadVideo } from '../../service';
import RegionUploadSuccess from '../../components/popups/regionUploadSuccess';

const AWS_URL = process.env.REACT_APP_AWS_URL;

const VideoRegion = () => {
  const navigate = useNavigate();
  const { videoID, id } = useParams();
  const [regionData, setRegionData] = useContext(RegionContext);
  //State Variable for Active Step
  const [activeStep, setActiveStep] = useState(1);
  const [finalVideo, setFinalVideo] = useState(false);

  //State Variable for Trimmed Video
  const [trimPoints, setTrimPoints] = useState([]);

  // Hold file for uploading
  const [videoFile, setVideoFile] = useState(null);
  //Variable to Hold Video Preview URL
  const [videoURL, setVideoURL] = useState('');
  const [videoDuration, setVideoDuration] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFail, setUploadFail] = useState(false);
  const [uploadProgress, setUplaodProgress] = useState(0);
  const [fromUpload, setFromUpload] = useState(false);
  const pageData = VideosData[id];

  const enableRecorder = () => {
    setActiveStep(videoURL ? 2 : 2);
  };

  const enableTrimmer = () => {
    setFromUpload(false);
    setActiveStep(3);
  };

  const enableUpload = () => {
    setFromUpload(true);
    setActiveStep(3);
  };

  const reRecordVideo = () => {
    setVideoURL('');
    setActiveStep(1);
  };

  const onUploadVideo = async () => {
    setActiveStep(4);
    var body = {
      type: videoFile.type,
      name: `${regionData.formData.id}/${regionData.regionId}/${regionData.dayId}/${videoID}`,
      keepOriginal: true
    };

    try {
      const {
        data: { url, finalName }
      } = await getUploadingFileData(body);
      const data = await uploadVideo(url, videoFile, setUplaodProgress, body.type);
      const UploadVideoData = {
        regionId: regionData.regionId,
        days: [
          {
            dayId: regionData.dayId,
            videos: {
              regionalUser: regionData.formData.id,
              videoURL: finalName,
              videoID,
              trimPoints
            }
          }
        ]
      };
      await uploadRegionalVideo(UploadVideoData);

      setUploadSuccess(true);
    } catch (error) {
      console.log('Upload Error', error);
      setUploadFail(true);
    }
  };

  const handleProcessComplete = () => {
    navigate(pageData.next);
    // if (videoID === 1) navigate('/champion-profile/' + profile._id);
  };

  return (
    <>
      {/* Show questionire */}
      {activeStep == 1 && (
        <Video.VideoQuestionaire
          {...pageData.questionire}
          enableDisplayRecorder={enableRecorder}
          enableUploadVideo={enableUpload}
          videoURL={videoURL}
          setVideoDuration={setVideoDuration}
          setVideoURL={setVideoURL}
          setVideoFile={setVideoFile}
          isRegional={true}
          regionVideoId={regionData?.vidIndex}
        />
      )}
      {/* Show Video Recorder */}
      {activeStep == 2 && (
        <Video.VideoRecord
          popupData={pageData.popup}
          videoURL={videoURL}
          setVideoURL={setVideoURL}
          setVideoFile={setVideoFile}
          setVideoDuration={setVideoDuration}
          enableTrimmer={enableTrimmer}
          isRegional={true}
        />
      )}

      {/* Show Video Trimmer */}
      {activeStep == 3 && (
        <Video.VideoTrimmer
          videoDuration={videoDuration}
          reRecordVideo={reRecordVideo}
          onUploadVideo={onUploadVideo}
          videoURL={videoURL}
          setTrimPoints={setTrimPoints}
          isRegional={true}
          fromUpload={fromUpload}
        />
      )}

      {/* Show Upload Screen */}
      {activeStep == 4 && (
        <Video.VideoUploader
          percentage={uploadProgress}
          nextPage={pageData.nextPage}
          processComplete={handleProcessComplete}
          success={false}
          fail={uploadFail}
          finalVideo={finalVideo}
          retryUpload={enableTrimmer}
          videoID={videoID}
          isRegional={true}
        />
      )}
      {uploadSuccess && <RegionUploadSuccess />}
    </>
  );
};

export default VideoRegion;
