/**
 * 📅 Last Modified: 04 Feb, 2023
 */
import React, { useContext } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { ProfileContext } from '../../contexts/ProfileContext';
import Button from '../../components/ui/button';
import { useTranslation } from 'react-i18next';

export default function VideoStart({ startHandler }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [profile] = useContext(ProfileContext);
  const { type } = profile;
  const btnHandler = () => {
    startHandler();
    // navigate('/get-started');
  };
  return (
    <section className="p-10 max-w-md mx-auto">
      <div className="flex items-center gap-2 border-b-2 border-black pb-4 mb-6">
        <AiOutlineInfoCircle className="text-6xl text-cblack" />
        <h1 className="text-2xl text-cblack font-semibold mb-4 text-center">
          Passons à ta vidéo...
        </h1>
      </div>
      <p className="-ml-1 mb-4 text-cred-300">
        <strong>Important :</strong> Toutes les vidéos doivent être en format portrait ! Si la prise
        est en format paysage, elle sera recadrée en format portrait.
      </p>
      <div className="mb-4">
        <b>Trimming, miniatures et titres :</b> Il est possible de couper (trimming) les premières
        ou dernières secondes de la vidéo avant de la télécharger. Sur le site web les vidéos seront
        illustrées par des miniatures titrées.
      </div>
      <div className="mb-4">
        <strong>Notez pour l’enregistrement :</strong>
      </div>
      <div className="pl-2 mb-12">
        <ul className="list-disc">
          <li className="mb-4">
            Enregistrez des <strong>vidéos courtes </strong> (au maximum une minute). Il est
            possible de couper les premières ou dernières secondes de la vidéo avant de la
            télécharger.
          </li>
          <li className="mb-4">
            <strong>L’audio : </strong>
            Pour faire passer un message, parlez à haute voix et faites attention aux bruits de
            fond.
          </li>
          <li className="mb-4">
            <strong>Les prises : </strong>
            Les prises peuvent être répétées.
          </li>
        </ul>
      </div>
      <div className="mb-12">
        <p>
          <strong>Téléchargement : </strong>
          Une fois la vidéo enregistrée, elle sera automatiquement téléchargée sur la page internet
          de votre équipe régionale. Vous puvez échanger la vidéo en répétant ce processus.
        </p>
      </div>
      <div className={`flex items-center justify-center`}>
        <Button.ButtonNext title="Continuer" className="ml-2 mt-10" handler={btnHandler} />
      </div>
    </section>
  );
}
