import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/ui/button';
import _ from 'lodash';

import Header from '../../components/partials/Header';
import { BluePlusIcon, GrayPlusIcon, ToggleArrow } from '../../components/icons';
import VideoStart from './videoStart';
import { RegionContext } from '../../contexts/regionContext';
import { getUserID, getRegions, getRegionData, getWeekDays } from '../../service';
import InputSelect from '../../components/common/InputSelect';
import ReuploadVideo from './reuploadVideo';
import { toast } from 'react-toastify';

export default function RegionVideosMenu() {
  const navigate = useNavigate();
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({});
  const [activeDay, setActiveDay] = useState(1);
  const [isStart, setIsStart] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [regionData, setRegionData] = useContext(RegionContext);
  const [isVideoAvailable, setVideoAvailable] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [isReupload, setIsReupload] = useState(false);
  const [reuploadData, setReuploadData] = useState({});
  const [availableData, setAvailableData] = useState({});

  useEffect(() => {
    getUserData();
    getDaysData();
    getPageData();
  }, []);

  const getUserData = async () => {
    try {
      const resData = await getUserID();
      let formData = {
        id: resData.data._id
      };
      setRegionData({ formData });
    } catch (e) {
      localStorage.removeItem('regionToken');
      navigate('/region/login');
    }
  };

  const getDataByRegionId = async (regionId) => {
    const data = await getRegionData({ regionId: regionId });
    const responseData = data.data.videoData;
    setAvailableData(responseData[0]);
    let uploadedVideos = [];
    responseData.map((regionItems) => {
      regionItems.days.map((day) => {
        day.videos.map((item) => {
          uploadedVideos.push(+item.videoID);
        });
      });
    });
    setVideoAvailable(uploadedVideos);
  };

  const getDaysData = async () => {
    try {
      const { data: weekDays } = await getWeekDays();
      let vId = 1;
      const updatedWeekDays = weekDays.data.map((item, key) => {
        const newItem = { ...item };
        newItem['videos'] = [];
        for (let i = 1; i <= 3; i++) {
          let videoData = {
            id: vId,
            label: `Vidéo ${i}`,
            value: `video-${vId}`
          };
          newItem['videos'].push(videoData);
          vId++;
        }
        return newItem;
      });
      await setMenuData(updatedWeekDays);
    } catch (e) {
      console.log(e);
    }
  };

  const getPageData = async () => {
    try {
      const { data: regionData } = await getRegions();
      const filteredRegions = regionData.map(({ name_fr, _id }) => ({
        value: _id,
        label: name_fr
      }));
      setRegions(filteredRegions);
    } catch (e) {
      console.log(e);
    }
  };

  const handleOption = (value) => {
    setActiveDay(value);
  };
  const handleClickVideo = (dayId, id, videoId, index) => {
    setSelectedData({ dayId: dayId, id: id, videoId: videoId });
    setRegionData({
      ...regionData,
      dayId: dayId,
      vidIndex: index + 1,
      regionId: selectedRegion.value
    });
    setIsStart(true);
  };

  const handleReuploadVideo = async (dayId, id, videoId, regiondata, vidId) => {
    try {
      setSelectedData({ dayId: dayId, id: id, videoId: videoId });
      setRegionData({
        ...regionData,
        dayId: dayId,
        vidIndex: vidId + 1,
        regionId: selectedRegion.value
      });
      let selecetedData = {};
      for (const items of availableData.days) {
        if (items.dayId === dayId) {
          for (const vidItems of items.videos) {
            if (vidItems.videoID === `${id}`) {
              selecetedData = vidItems;
              selecetedData['dayId'] = regiondata?._id ? regiondata?._id : '';
              selecetedData['dayName'] = regiondata.label ? regiondata.label : '';
              selecetedData['videoId'] = vidId + 1 || '';
            }
          }
        }
      }
      if (selecetedData) {
        setReuploadData(selecetedData);
        setIsReupload(true);
      }
    } catch (e) {
      console.log('error', e);
      toast(`quelque chose s'est mal passé`);
    }
  };

  const nextHandler = () => {
    if (menuData) {
      for (const items of menuData) {
        for (const [key, itm] of items.videos.entries()) {
          if (!isVideoAvailable.includes(itm.id)) {
            handleClickVideo(items._id, itm.id, itm.value, key);
            return;
          }
        }
      }
    }
  };

  const validHandler = () => {
    setIsStart(false);
    setIsReupload(false);
    navigate(`/region/video/${selectedData.id}/${selectedData.videoId}`);
  };

  const regionHandler = async (val) => {
    await setSelectedRegion(val);
    await getDataByRegionId(val.value);
  };

  const onLogout = async () => {
    localStorage.removeItem('regionToken');
    navigate('/region/login');
  };

  const onContinueHandle = async () => {
    setIsReupload(false);
    navigate(`/region/video/${selectedData.id}/${selectedData.videoId}`);
  };

  const onCloseReupload = () => {
    setIsStart(false);
    setIsReupload(false);
  };

  const closeHandler = () => {
    navigate('/region/welcome-region');
  };

  return (
    <>
      <Header type="title" title="LE BLOG VIDÉO" closeHandler={closeHandler} />
      {!isStart && !isReupload && (
        <div className="mx-4 overflow-auto h-screen mb-10">
          <div className="my-10 px-5">
            <p className="text-lg quickSand text-center">
              <b>Choisissez votre région et cliquez ensuite sur le jour & le numéro de la vidéo</b>{' '}
              que vous voulez regarder ou enregistrer ou télécharger ou échanger,
            </p>
          </div>
          <div className="pb-5 px-4">
            {/* Region */}
            <InputSelect
              label="Région"
              labelClassName="font-bold quickSand w-3/4"
              placeholder="Région"
              className="my-1"
              name="region"
              value={selectedRegion}
              options={regions}
              onChange={(val) => {
                regionHandler(val);
              }}
            />
          </div>
          {selectedRegion?.value && (
            <div className="bg-cwhite-500 mx-5 p-3 px-5 rounded-lg">
              {menuData.length &&
                menuData.map((items, key1) => {
                  return (
                    <div key={key1} className="">
                      <div
                        onClick={() => {
                          handleOption(items.orderKey);
                        }}
                        className={`flex justify-between items-center cursor-pointer border-b border-[#D9D9D9] py-3  ${
                          activeDay == items.orderKey
                            ? 'bg-cgold-600 text-white rounded-lg px-4'
                            : ''
                        }`}>
                        <div className="font-bold text-lg">{items.label}</div>
                        <div
                          className={`${
                            activeDay == items.orderKey ? 'origin-center rotate-180' : ''
                          }`}>
                          {<ToggleArrow isSelected={activeDay == items.orderKey} />}
                        </div>
                      </div>
                      {activeDay == items.orderKey && (
                        <div className="px-4 my-3">
                          {items.videos.map((itm, key2) =>
                            isVideoAvailable.includes(itm.id) ? (
                              <div
                                onClick={() => {
                                  handleReuploadVideo(items._id, itm.id, itm.value, items, key2);
                                }}
                                className="flex justify-between items-center mb-2 cursor-pointer"
                                key={key2}>
                                <div>{itm.label}</div>
                                <div className="text-red-700">
                                  {<GrayPlusIcon className="text-red-700" />}
                                </div>
                              </div>
                            ) : (
                              <div
                                onClick={() => {
                                  handleClickVideo(items._id, itm.id, itm.value, key2);
                                }}
                                className="flex justify-between items-center mb-2 cursor-pointer"
                                key={key2}>
                                <div>{itm.label}</div>
                                <div>{<BluePlusIcon />}</div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          )}
          <div className="mb-10">
            {isVideoAvailable.length !== 21 && (
              <div className="text-center mt-10">
                <Button.ButtonNext
                  title="Continuer"
                  disabled={!selectedRegion?.value}
                  handler={() => {
                    nextHandler();
                  }}
                  className="mx-auto"></Button.ButtonNext>
              </div>
            )}
          </div>
          <div className="mb-6">
            <p className="mb-3 mx-3 text-center quickSand">
              Si vous avez des questions ou besoin de support technique, adressez-vous à Hugo
              Delouche :
            </p>
            <p className="text-center">
              <b>Portable : 06 88 93 82 12</b>
              <br />
              <b>E-mail : hugodelouche2@gmail.com</b>
            </p>
          </div>
          <div className="text-center mb-6">
            <button
              onClick={() => {
                onLogout();
              }}
              className="font-bold underline text-lg">
              DÉCONNECTER
            </button>
          </div>
        </div>
      )}
      {isStart && (
        <div className="absolute inset-0">
          <div className="bg-white h-screen w-screen">
            <VideoStart startHandler={validHandler} />
          </div>
        </div>
      )}
      {isReupload && (
        <div className="absolute inset-0">
          <div className="bg-white h-screen w-screen">
            <ReuploadVideo
              reuploadData={reuploadData}
              onContinueHandle={onContinueHandle}
              onCloseReupload={onCloseReupload}
            />
          </div>
        </div>
      )}
    </>
  );
}
