import HelpLinePopup from './HelpLinePopup';
import DeviceTypePopup from './DeviceTypePopup';
import DownloadPDF from './downloadPDF';
import ImageCropPopup from './ImageCropPopup';
import OptionSelectorPopup from './OptionSelectorPopup';
import ProfileComplete from './ProfileComplete';
import VideoUploadedPopup from './VideoUploadedPopup';
import OnVideoUploadFailed from './OnVideoUploadFailed';
import VideoSupport from './videoSupport';
import FullPagePopup from './fullPagePopup';
import Validation from './validation';
import ProfileIncomplete from './profileIncomplete';
import NoProfilePreview from './noProfilePreview';
import FieldsMissing from './fieldsMissingPopup';
import UnsavedChanges from './usavedChangesPopup';

const Popups = {
  HelpLinePopup,
  DownloadPDF,
  ImageCropPopup,
  ProfileComplete,
  OptionSelectorPopup,
  DeviceTypePopup,
  VideoUploadedPopup,
  OnVideoUploadFailed,
  VideoSupport,
  FullPagePopup,
  Validation,
  ProfileIncomplete,
  NoProfilePreview,
  FieldsMissing,
  UnsavedChanges
};

export default Popups;
