/* eslint-disable no-debugger */
import * as Yup from 'yup';

export const genericFormSchema = Yup.object().shape({
  firstName: Yup.string().max(40, 'firstName.max').min(2, 'firstName.min'),
  familyName: Yup.string().max(40, 'familyName.max').min(2, 'familyName.min'),
  birthYear: Yup.number()
    .min(new Date().getFullYear() - 100, 'birthYear.min')
    .max(new Date().getFullYear(), 'birthYear.max'),
  city: Yup.string().max(50, 'city.max'),
  postcode: Yup.string().max(8, 'postcode.max'),
  bestApprentice: Yup.object().optional(),
  bestWorker: Yup.object().optional(),
  natFinalsYear: Yup.number()
    .min(new Date().getFullYear() - 100, 'natFinalsYear.min')
    .max(new Date().getFullYear(), 'natFinalsYear.max'),
  natFinalsRank: Yup.object().optional(),
  euroSkillsYear: Yup.number('euroSkillsYear.number')
    .min(new Date().getFullYear() - 100, 'euroSkillsYear.min')
    .max(new Date().getFullYear(), 'euroSkillsYear.max')
    .optional(),
  natFinalsRegion: Yup.object().optional(),
  trainingAndDiploma1: Yup.string().max(90, 'trainingAndDiploma.max'),
  trainingAndDiploma2: Yup.string().max(90, 'trainingAndDiploma.max').optional(),
  trainingAndDiploma3: Yup.string().max(90, 'trainingAndDiploma.max'),
  worldSkillsProfession: Yup.object().optional(),
  worldSkillsYear: Yup.number()
    .min(new Date().getFullYear() - 100, 'worldSkillsYear.min')
    .max(new Date().getFullYear(), 'worldSkillsYear.max')
    .optional(),
  currentCompanyCityDept: Yup.string().max(50, 'currentCompanyCityDept.max').optional(),
  currentJobName: Yup.string().optional(''),
  gender: Yup.object(),
  facebookAddress: Yup.string().optional().url('facebookAddress.url'),
  instagramAddress: Yup.string().optional().url('instagramAddress.url'),
  tiktokAddress: Yup.string().optional().url('tiktokAddress.url'),
  region: Yup.object().typeError('region'),
  mobileNumber: Yup.string().matches(/^\+?\d(?:[\d\s]*)$/, 'mobileNumber.correctFormat'),
  montherLanguage: Yup.object(),
  otherLanguage: Yup.string().optional(),
  specialJobTitle: Yup.object().typeError('specialJobTitle'),
  profession: Yup.object(),
  swissChampionYear: Yup.number()
    .min(new Date().getFullYear() - 100, 'swissChampionYear.min')
    .max(new Date().getFullYear(), 'swissChampionYear.max')
    .optional(),
  swissChampionRank: Yup.object().optional(),
  associationProfession: Yup.object().typeError('associationProfession'),
  vocationSchool: Yup.object().typeError('vocationSchool'),
  functions: Yup.array(),

  recentProfession1: Yup.string().max(90, 'recentProfession.max'),
  recentProfession2: Yup.string().nullable().max(90, 'recentProfession.max'),
  recentProfession3: Yup.string().nullable().max(90, 'recentProfession.max'),
  linkedinAddress: Yup.string().optional().url('linkedinAddress.url'),
  participationType: Yup.object().optional().nullable(),
  abilympicsProfession: Yup.object().optional(),
  consent: Yup.boolean().isTrue('consent').nullable()
});

export const LoginSchema = Yup.object({
  email: Yup.string().email('email.correctEmail').required('email.required'),
  lang: Yup.object()
});

export const videoHelpSchema = Yup.object({
  tel: Yup.string().min(9).max(15).required('Veuillez saisir votre prénom!')
});
