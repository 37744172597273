import React from 'react';
import { FaCircle, FaCheckCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export default function ListItem({ item, isEnabled, onClick }) {
  const { i18n } = useTranslation();

  return (
    <li className="list-item my-3" onClick={onClick}>
      <div className="flex justify-between items-center">
        <span className="mr-2 text-lg">{item[`name_${i18n.language}`]}</span>
        <div>
          {isEnabled ? (
            <FaCheckCircle className="icon active" size={25} />
          ) : (
            <FaCircle className="icon" size={25} />
          )}
        </div>
      </div>
    </li>
  );
}
