import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { regionalLoginMagicVerify } from '../../service';
import { ProfileContext } from '../../contexts/ProfileContext';

function RegionMagic() {
  const navigate = useNavigate();
  const [_, setProfileData] = useContext(ProfileContext);
  const [searchParams] = useSearchParams();

  const [ref, setReferral] = useState('');

  const handleVerification = async (refCode = ref) => {
    try {
      const { data: { token = '' } = {} } = await regionalLoginMagicVerify({ token: refCode });
      localStorage.setItem('regionToken', token);
      fetchUser(token);
    } catch (e) {
      localStorage.removeItem('token');
      console.log('ERROR', e);
      navigate('/region/login');
    }
  };

  const fetchUser = async () => {
    try {
      navigate('/region/welcome-region');
    } catch (error) {
      console.log('EEE', error);
    }
  };

  useEffect(() => {
    if (ref) return;

    const refCode = searchParams.get('ref');
    setReferral(refCode);
    if (!refCode) return;
    handleVerification(refCode);
  }, [searchParams]);

  return <></>;
}

export default RegionMagic;
