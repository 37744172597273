import React, { useContext } from 'react';

import InfoPopup from './controls/InfoPopup';
import RedoScreen from './controls/RedoScreen';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProfileContext } from '../../contexts/ProfileContext';

const VideoRecord = ({
  popupData,
  videoURL,
  setVideoURL,
  setVideoFile,
  setVideoDuration,
  enableTrimmer,
  videoId,
  isRegional = false
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [profile] = useContext(ProfileContext);
  const { type } = profile;
  // Could be used if we want to auto open camera when page opens
  // const videoInputRef = createRef();
  // useEffect(() => {
  //   if (!videoURL) {
  //     if (videoInputRef.current) videoInputRef.current.click();
  //   }
  // }, [videoURL]);

  return (
    <section className="video-recorder">
      {!videoURL && (
        <label className="record-video-input">
          <span className="record-btn"></span>
          <input
            type="file"
            accept="video/*"
            capture="user"
            onChange={function (e) {
              var file = e.target.files[0];
              setVideoFile(file);
              setVideoURL(URL.createObjectURL(file));
            }}
          />
        </label>
      )}
      {videoURL && (
        <video
          src={videoURL}
          autoPlay
          playsInline
          loop
          muted
          onLoadedMetadata={function (e) {
            setVideoDuration(e.target.duration);
          }}
        />
      )}
      {!isRegional && (
        <InfoPopup
          data={{
            title: t(`videoPage.${videoId}.pop-up.title`),
            description: t(`videoPage.${videoId}.pop-up.subtitle`)
          }}
          show={!videoURL}
          isRegional={isRegional}
        />
      )}
      <RedoScreen
        show={videoURL}
        setVideoURL={setVideoURL}
        onOkay={() => enableTrimmer()}
        isRegional={isRegional}
      />
    </section>
  );
};

export default VideoRecord;
