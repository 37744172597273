import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CloseIcon } from '../icons';

const FullPagePopup = ({ closeLink = '/my-profile', onCloseCallback, children }) => {
  const navigate = useNavigate();

  return (
    <div className="fixed top-0 left-0 w-full h-screen z-[60]">
      <div
        className="bg-grey-100 opacity-40 h-screen w-full"
        onClick={() => {
          if (onCloseCallback) {
            onCloseCallback(() => navigate(closeLink));
            return;
          }
          navigate(closeLink);
        }}
      />
      <div
        className="fixed h-[88vh] bottom-0 left-0 w-full rounded-t-3xl bg-white"
        style={{ boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.15)' }}>
        <div className="flex h-[5%] items-center justify-center">
          <span className="relative bg-black opacity-20 h-[2px] w-24" />
          <button
            className="p-1 rounded-full bg-grey-100 absolute right-3 top-3"
            onClick={() => {
              if (onCloseCallback) {
                onCloseCallback(() => navigate(closeLink));
                return;
              }
              navigate(closeLink);
            }}>
            <CloseIcon />
          </button>
        </div>
        <div className="h-[95%] overflow-y-auto overflow-x-hidden p-10 pb-10">{children}</div>
      </div>
    </div>
  );
};

export default FullPagePopup;
