import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';

import { VideosData } from '../../pages/PgVideos/data/videosData';
import { ProfileContext } from '../../contexts/ProfileContext';
import { checkVideoUrlValidity } from '../../utils';
import { getUploadingFileData, postVideoLink, uploadVideo } from '../../service';

import Video from '.';
import VideoProcessing from './VideoProcessing';

const VIDEO_URL = process.env.REACT_APP_AWS_URL + 'profile-videos/';

const FullVideoUploadFlow = ({ videoID, videosInformation, orgPrefix, allowedToRecord = true }) => {
  const [profile, setProfileData] = useContext(ProfileContext);
  //State Variable for Active Step
  const [activeStep, setActiveStep] = useState(1);
  const [finalVideo, setFinalVideo] = useState(false);

  //State Variable for Trimmed Video
  const [trimPoints, setTrimPoints] = useState([]);

  // Hold file for uploading
  const [videoFile, setVideoFile] = useState(null);
  //Variable to Hold Video Preview URL
  const [videoURL, setVideoURL] = useState('');
  const [videoDuration, setVideoDuration] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFail, setUploadFail] = useState(false);
  const [uploadProgress, setUplaodProgress] = useState(0);
  const [fromUpload, setFromUpload] = useState(false);
  // Video is processing
  const [isVideoProcessing, setIsVideoProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCompetitor, setIsCompetitor] = useState(false);

  useEffect(() => {
    if (uploadSuccess) return;
    const validateVideoUrl = async () => {
      try {
        setIsCompetitor(false);
        let index = _.findIndex(
          profile.videos?.videos,
          ({ videoID: tVideoID }) => parseInt(tVideoID) === videoID
        );
        if (index >= 0) {
          const vidURL = profile.videos.videos[index].videoURL;
          if (vidURL === undefined) {
            return;
          }
          const videoURL = VIDEO_URL + vidURL;
          const isValid = await checkVideoUrlValidity(videoURL);
          if (isValid) {
            setVideoURL(videoURL);
          } else {
            setIsVideoProcessing(true);
          }
        }
      } catch (error) {
        console.error('Failed to load video');
      } finally {
        setIsLoading(false);
      }
    };

    validateVideoUrl();
  }, [profile]);

  const pageData = VideosData[`video-${videoID}`];

  const enableRecorder = () => {
    setActiveStep(videoURL ? 2 : 2);
  };

  const enableTrimmer = () => {
    setFromUpload(false);
    setActiveStep(2);
  };

  const reRecordVideo = () => {
    // setVideoURL('');
    setActiveStep(1);
  };

  const checKIfItsFinalVideo = () => {
    const nextVideoActive = videosInformation?.[videoID]?.isActive;
    if (!nextVideoActive) {
      setFinalVideo(true);
    }
  };

  const pushVideo = (video) => {
    try {
      if (_.isEmpty(profile.videos) || _.isEmpty(profile.videos?.videos))
        setProfileData({ ...profile, videos: { videos: [video] } });
      else {
        let videos = profile.videos?.videos || [];
        const index = _.findIndex(
          videos,
          ({ videoID: tempvideoID }) => parseInt(tempvideoID) === videoID
        );
        if (index > -1) videos[index] = video;
        else videos.push(video);
        setProfileData({ ...profile, videos: { videos } });
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const onUploadVideo = async () => {
    let index = _.findIndex(
      profile.videos?.videos,
      ({ videoID: tVideoID }) => parseInt(tVideoID) === videoID
    );
    if (isCompetitor) {
      index = 0;
    }
    if (!videoFile && !_.isEmpty(profile.videos.videos[index])) {
      await postVideoLink({
        videoURL: profile.videos.videos[index].videoURL,
        videoID,
        trimPoints,
        strengthId: profile.videos.videos[index].strengthId
      });
      pushVideo({
        videoURL: profile.videos.videos[index].videoURL,
        videoID,
        trimPoints,
        strengthId: profile.videos.videos[index].strengthId
      });
      setActiveStep(3);
      setUplaodProgress(100);
      return setUploadSuccess(true);
    }

    checKIfItsFinalVideo();

    setActiveStep(3);
    setUploadSuccess(false);
    setUploadFail(false);
    setUplaodProgress(0);

    var body = {
      type: videoFile.type,
      name: `${profile._id}/${videoID}`, //videoFile.name,
      keepOriginal: true,
      isProfileVideo: true
    };

    try {
      const {
        data: { url, finalName }
      } = await getUploadingFileData(body);

      await uploadVideo(url, videoFile, setUplaodProgress, body.type);
      await postVideoLink({
        videoURL: finalName,
        videoID,
        trimPoints
      });
      pushVideo({
        videoURL: finalName,
        videoID,
        trimPoints
      });
      setUploadSuccess(true);
    } catch (error) {
      console.log('Upload Error', error);
      setUploadFail(true);
    }
  };

  const enableUpload = () => {
    setFromUpload(true);
    setActiveStep(2);
  };

  return (
    <>
      {/* Show Processing Popup */}
      {isLoading ? (
        <></>
      ) : isVideoProcessing ? (
        <VideoProcessing orgPrefix={orgPrefix} />
      ) : (
        <>
          {/* Show questionire */}
          {activeStep == 1 && (
            <Video.VideoQuestionaire
              {...pageData?.questionire}
              enableDisplayRecorder={enableRecorder}
              enableUploadVideo={enableUpload}
              videoURL={videoURL}
              setVideoDuration={setVideoDuration}
              setVideoURL={setVideoURL}
              setVideoFile={setVideoFile}
              isCompetitor={isCompetitor}
              videoID={videoID}
              orgPrefix={orgPrefix}
              allowedToRecord={allowedToRecord}
            />
          )}

          {/* Show Video Trimmer */}
          {activeStep == 2 && (
            <Video.VideoTrimmer
              videoDuration={videoDuration}
              reRecordVideo={reRecordVideo}
              onUploadVideo={onUploadVideo}
              videoURL={videoURL}
              setTrimPoints={setTrimPoints}
              fromUpload={fromUpload}
              isCompetitor={isCompetitor}
              orgPrefix={orgPrefix}
            />
          )}

          {/* Show Upload Screen */}
          {activeStep == 3 && (
            <Video.VideoUploader
              percentage={uploadProgress}
              resetVideoPage={() => {
                setActiveStep(1);
                setUploadSuccess(false);
                setUploadFail(false);
                setFromUpload(false);
                setUplaodProgress(0);
                setTrimPoints([]);
                setVideoFile(null);
                setVideoURL('');
                setIsVideoProcessing(false);
              }}
              nextPage={`/video/${videoID + 1}`}
              success={uploadSuccess}
              fail={uploadFail}
              finalVideo={finalVideo}
              retryUpload={enableTrimmer}
              videoID={videoID}
              isCompetitor={isCompetitor}
              orgPrefix={orgPrefix}
            />
          )}
        </>
      )}
    </>
  );
};

export default FullVideoUploadFlow;
