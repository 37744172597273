import React from 'react';
import Button from '../ui/button';

export default function ProfileComplete({
  finishProfile,
  buttonText = 'Save and Exit',
  title,
  description
}) {
  return (
    <div className="fixed top-0 left-0 flex items-center justify-center h-screen w-full z-50 bg-white/20 backdrop-blur-sm">
      <div className="w-5/6 h-auto px-5 py-5 bg-white rounded shadow-lg flex flex-col justify-center items-center">
        <div className="content flex flex-col justify-center !w-[100%] !h-[100%] !mx-0 !my-0">
          <div className="text-center">
            <img src="/images/icons/both-thumbs-up.svg" className="w-32 mx-auto mb-6" />
          </div>
          <div className="px-4">
            <h2
              className="w-full text-center font-bold text-2xl mb-3"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
              className="w-full text-center text-lg"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>

          <div className="flex flex-col gap-4 mt-6">
            <Button.ButtonNext
              title={buttonText}
              className="w-fit mx-auto"
              handler={finishProfile}></Button.ButtonNext>
          </div>
        </div>
      </div>
    </div>
  );
}
