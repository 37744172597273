import React, { useContext } from 'react';
import { ProfileContext } from '../../../contexts/ProfileContext';
import { Spinner } from '../../common';

export default function ButtonRounded({
  onClick,
  className,
  children,
  typeClass = 'theme-500 theme-button-round',
  loading,
  ...props
}) {
  const [profile] = useContext(ProfileContext);
  const { masterData: { customConfigurations: { colors, buttonRadius } = {} } = {} } = profile;

  return (
    <button
      {...props}
      className={`text-white px-4 py-2 font-bold flex items-center justify-center ${typeClass} ${className} disabled:opacity-60`}
      style={{ backgroundColor: colors?.primary, borderRadius: buttonRadius }}
      disabled={loading}
      onClick={onClick}>
      {children} <Spinner isSubmitting={loading} />
    </button>
  );
}
