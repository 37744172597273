import React from 'react';
import Header from '../../partials/Header';
import Trimmer from './Trimmer';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../ui/button';
import { getTranslation } from '../../../utils';

const VideoTrimmer = ({
  videoDuration,
  videoURL,
  reRecordVideo,
  onUploadVideo,
  setTrimPoints,
  isRegional = false,
  isCompetitor = false,
  fromUpload = false,
  orgPrefix
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goHome = () => {
    if (isRegional) {
      navigate('/region/video-region');
    } else {
      navigate('/my-profile');
    }
  };

  return (
    <>
      {isRegional && <Header type={'home'} goHome={goHome} orgPrefix={orgPrefix} />}

      <section>
        <div className="content">
          {(isRegional || isCompetitor) && (
            <>
              <div className="mb-8">
                <h1 className="heading mb-2"> {t(`videoPage.videoTrim.regionTitle`)}</h1>
                {fromUpload ? (
                  <p>
                    {isCompetitor
                      ? t(`videoPage.videoTrim.competitor.fromUpload`)
                      : t(`videoPage.videoTrim.region.fromUpload`)}
                  </p>
                ) : (
                  <p>
                    {isCompetitor
                      ? t(`videoPage.videoTrim.competitor.fromRecord`)
                      : t(`videoPage.videoTrim.region.fromRecord`)}
                  </p>
                )}
              </div>

              <Trimmer
                videoDuration={videoDuration}
                reRecordVideo={reRecordVideo}
                onUploadVideo={onUploadVideo}
                videoURL={videoURL}
                setTrimPoints={setTrimPoints}
                isRegional={isRegional || isCompetitor}
                fromUpload={fromUpload}
              />
            </>
          )}

          {!isRegional && !isCompetitor && (
            <div className="fixed top-0 left-0 z-[60] w-full h-full bg-white">
              <Header
                rightSubmitBtn={
                  <Button.ButtonRounded onClick={onUploadVideo}>
                    <p
                      className="text-lg text-white font-semibold"
                      dangerouslySetInnerHTML={{
                        __html: getTranslation(t, `videoUploadSubmit`, orgPrefix)
                      }}
                    />
                  </Button.ButtonRounded>
                }
                orgPrefix={orgPrefix}
              />
              <div className="p-10 mt-32">
                <Trimmer
                  videoDuration={videoDuration}
                  reRecordVideo={reRecordVideo}
                  onUploadVideo={onUploadVideo}
                  videoURL={videoURL}
                  setTrimPoints={setTrimPoints}
                  isRegional={false}
                  fromUpload={fromUpload}
                  orgPrefix={orgPrefix}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default VideoTrimmer;
