import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { verifyLink, profileApi } from '../service';

function Magic() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [ref, setReferral] = useState('');

  const handleVerification = async (refCode = ref, lang) => {
    try {
      const { data } = await verifyLink({ token: refCode });
      try {
        localStorage.setItem('token', data.token);
      } catch (error) {
        console.log('LS Unavailable');
      }
      profileApi.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
      navigate(`/splash?lang=${lang || 'en'}`);
    } catch (e) {
      console.log('ERROR', e);
      navigate('/');
    }
  };

  useEffect(() => {
    if (ref) return;

    const refCode = searchParams.get('ref');
    const lang = searchParams.get('lang');
    setReferral(refCode);
    if (!refCode) return;
    handleVerification(refCode, lang);
  }, [searchParams]);

  return <></>;
}

export default Magic;
