import axios from 'axios';

const uninterceptedAxiosInstance = axios.create();

//Get Token
export const getToken = () => {
  var token = localStorage.getItem('token');
  if (!token) {
    return null;
  }
  return token;
};
//Convert Base 64 Image to Blob
export function base64ToBlob(base64) {
  const cleanedBase64 = base64.replace(/\s/g, '');
  const binary = atob(cleanedBase64.split(',')[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }

  const mimeString = cleanedBase64.split(',')[0].split(':')[1].split(';')[0];
  // return new Blob([new Uint8Array(array)], { type: mimeString });
  const extension = mimeString.split('/')[1];
  const blob = new Blob([new Uint8Array(array)], { type: mimeString });
  blob.name = `image.${extension}`;
  return blob;
}

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const checkVideoUrlValidity = async (videoURL) => {
  try {
    const response = await uninterceptedAxiosInstance.head(`${videoURL}?_=${new Date().getTime()}`);
    // If the request completes successfully, the resource exists
    return response.status >= 200 && response.status < 300;
  } catch (error) {
    return false;
  }
};

export const trackEvent = (event, data) => {
  if (typeof window.gtag === 'function') {
    window.gtag('event', event, data);
  }
};

export const changeFavicon = (url) => {
  const favicon = document.querySelector('link[rel="icon"]');
  if (url) {
    favicon.href = url;
  }
  return;
};
export const getSortedList = (list, lang, property = 'name') => {
  return list.sort((a, b) =>
    a[`${property}_${lang}`]?.localeCompare(b[`${property}_${lang}`], lang)
  );
};

export const getTranslation = (t, key, orgPrefix = '', array = false, index = 0, subKey = '') => {
  let translation = t(key, { returnObjects: true });

  if (array) translation = translation?.[index]?.[subKey];

  if (translation?.[orgPrefix]) return translation[orgPrefix];
  else return translation?.default ? translation.default : key?.toString();
};
