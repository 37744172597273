import React from 'react';
import Button from '../../components/ui/button';
import { useNavigate } from 'react-router-dom';
import { RegionWelcome } from '../../assets/logos';

export default function WelcomeRegion() {
  const navigate = useNavigate();
  const nextHandler = () => {
    navigate('/region/video-region');
  };
  return (
    <div className="mx-20">
      <div className="mb-10">
        <div className="bg-cgold-600 h-[60vh] rounded-b-3xl w-full flex justify-center">
          <div className="flex flex-col-reverse mb-8">
            <RegionWelcome />
          </div>
        </div>
      </div>
      <div className="text-center mx-20 mb-20">
        <h1 className="text-3xl font-bold quickSand">MERCI</h1>
        <p className="quickSand">de contribuer au blog vidéo !</p>
      </div>
      <div className="text-center mt-10">
        <Button.ButtonNext
          title="Je commence"
          handler={nextHandler}
          className="mx-auto"></Button.ButtonNext>
      </div>
    </div>
  );
}
