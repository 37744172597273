import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { CiRedo } from 'react-icons/ci';
import { useTranslation } from 'react-i18next';
import { getTranslation } from '../../utils';

export default function OnVideoUploadFailed({ retryUpload, orgPrefix }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  return (
    <div className="sm-popup helpline p-4" style={{ display: open ? 'block' : 'none' }}>
      <div className="content-container">
        <div className="content">
          <button className="btn-times justify-center items-center" onClick={() => setOpen(false)}>
            <FaTimes />
          </button>
          <div className="text-center">
            <img src="/images/icons/thumbs-down.png" className="w-20 mx-auto mb-6" />
          </div>
          <h2 className="heading">{getTranslation(t, 'failUpload.title', orgPrefix)}</h2>
          <p className="description text-sm">
            {getTranslation(t, 'failUpload.subtitle', orgPrefix)}
          </p>
          <div className="flex flex-col gap-4 mt-6">
            <button className="text-4xl mx-auto" onClick={retryUpload}>
              <CiRedo />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
