import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import { UploadIcon } from '../../assets/logos';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Button from '../ui/button';

import { InfoIcon } from '../icons';
import { getTranslation } from '../../utils';

export default function VideoQuestionaire({
  mainSection,
  secondSection,
  enableDisplayRecorder,
  enableUploadVideo,
  videoURL,
  setVideoDuration,
  setVideoURL,
  setVideoFile,
  isRegional = false,
  videoID,
  regionVideoId = 1,
  orgPrefix,
  allowedToRecord
}) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const fileInputRef = useRef(null);
  const [isSetVideoUrl, setVideoUrl] = useState(false);
  const continuteButtonHandler = () => {
    enableDisplayRecorder();
  };

  const uploadAndContinue = () => {
    fileInputRef.current.click();
    // enableUploadVideo();
  };

  return (
    <>
      {!isRegional && (
        <>
          <div className="border-[1px] border-black rounded-xl p-6 mt-8">
            <p
              className="text-lg text-black font-semibold mb-4 w-full"
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, `videoTips.heading`, orgPrefix)
              }}
            />
            <div
              className="video-tips text-black text-base w-full"
              dangerouslySetInnerHTML={{
                __html: getTranslation(
                  t,
                  `videoTips.video`,
                  orgPrefix,
                  true,
                  videoID - 1,
                  'description'
                )
              }}
            />

            <Button.ButtonRounded
              className={'flex gap-2 items-center mt-6'}
              onClick={() => navigate(`/video-tips/${videoID}`)}>
              <InfoIcon />
              <p
                className="text-lg text-white font-semibold"
                dangerouslySetInnerHTML={{
                  __html: getTranslation(t, `videoTips.button`, orgPrefix)
                }}
              />
            </Button.ButtonRounded>
          </div>

          <div className="flex items-center justify-center w-full mt-10">
            <Button.ButtonRounded
              onClick={() => {
                if (!allowedToRecord) {
                  toast.error(
                    videoID === 1
                      ? getTranslation(t, `videoTips.strengthMissingError`, orgPrefix)
                      : getTranslation(t, `videoTips.roleMissingError`, orgPrefix)
                  );
                  return;
                }
                uploadAndContinue();
              }}>
              <p
                className="text-lg text-white font-semibold"
                dangerouslySetInnerHTML={{
                  __html: getTranslation(t, `videoTips.recordBtn`, orgPrefix)
                }}
              />
            </Button.ButtonRounded>
          </div>
        </>
      )}

      {isRegional && (
        <div className="q">
          <div className="flex justify-between">
            <h1 className="heading">{mainSection.heading}</h1>
            <h1 className="text-base text-medium">Vidéo {regionVideoId}</h1>
          </div>
          <p className="p mb-8">{mainSection.description}</p>
        </div>
      )}
      {isRegional && (
        <div className={`mb-4 mt-10`}>
          <div className="mb-6">
            <strong className="">{secondSection.heading}</strong>
            <p className="p" dangerouslySetInnerHTML={{ __html: secondSection.description }}></p>
          </div>
          <div>{!secondSection.noteFirst && <p className="p">{secondSection.note}</p>}</div>
        </div>
      )}
      {isRegional && (
        <>
          <div className="mt-40"></div>
          <div className="text-center mt-20">
            <button
              className="w-fit px-5 py-2 rounded-md border-2 border-black"
              onClick={uploadAndContinue}>
              <div className="flex items-center">
                <span className="icon-container mr-4">
                  <UploadIcon className="icon" />
                </span>
                <span>Télécharger une vidéo</span>
              </div>
            </button>
          </div>
          <div className="text-center mt-10">
            <button
              className="w-fit px-4 py-2 text-white rounded-md bg-cblue-500"
              onClick={continuteButtonHandler}>
              <div className="flex items-center">
                <span>Enregistrer une vidéo</span>
                <span className="icon-container ml-4">
                  <FaArrowRight className="icon" />
                </span>
              </div>
            </button>
          </div>
        </>
      )}
      <input
        ref={fileInputRef}
        id="FileInput"
        className="hidden"
        type="file"
        accept="video/*"
        onChange={function (e) {
          var file = e.target.files[0];
          setVideoFile(file);
          setVideoURL(URL.createObjectURL(file));
          setVideoUrl(true);
        }}
      />
      {videoURL && isSetVideoUrl && (
        <video
          src={videoURL}
          onLoadedMetadata={function (e) {
            enableUploadVideo();
            setVideoDuration(e.target.duration);
          }}
        />
      )}
    </>
  );
}
