import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { useTranslation } from 'react-i18next';
import Button from '../ui/button';
import { getTranslation } from '../../utils';

export default function ImageCropPopup({ imageUrl, onImageSet, turnOffCropper, orgPrefix }) {
  const { t } = useTranslation();
  const imageRef = useRef(null);
  const [cropper, setCropper] = useState();

  const getCropData = async () => {
    if (typeof cropper !== 'undefined') {
      const blobImage = await dataToBlob(cropper.getCroppedCanvas().toDataURL());
      onImageSet(blobImage);
      turnOffCropper();
    }
  };
  return (
    <section className="">
      {/* <div className="text-center mt-6">
        <div className="heading">{t(`imageProfile.imgCrop.title`)}</div>
        <div>{t(`imageProfile.imgCrop.subtitle`)}</div>
      </div> */}
      <Cropper
        style={{ height: 400, width: '100%', marginTop: '20px' }}
        initialAspectRatio={1}
        preview=".img-preview"
        src={imageUrl}
        ref={imageRef}
        viewMode={1}
        guides={true}
        minCropBoxHeight={100}
        minCropBoxWidth={100}
        background={false}
        zoomable={false}
        responsive={true}
        checkOrientation={false}
        aspectRatio={1}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
      />
      <div className="w-full flex items-center justify-center mt-2">
        <Button.ButtonRounded className="btn btn-primary my-6" onClick={getCropData}>
          {getTranslation(t, `imageProfile.confirm`, orgPrefix)}
        </Button.ButtonRounded>
      </div>
    </section>
  );
}

const dataToBlob = async (imageData) => {
  return await (await fetch(imageData)).blob();
};
