import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Popups } from '../../components';
import Loader from '../../components/common/Loader';
import FullVideoUploadFlow from '../../components/video/fullVideoUploadFlow';
import { ProfileContext } from '../../contexts/ProfileContext';

import { getStrengths, getRoles, linkStrengthToVideo, linkRoleToVideo } from '../../service';

import { FaRedo } from 'react-icons/fa';
import { DownArrowIcon, VideoPermissionsIcon } from '../../components/icons';
import { getSortedList, getTranslation } from '../../utils';
import { Link } from 'react-router-dom';

export default function Video() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [showStrengthsPopup, setShowStrengthsPopup] = useState(false);
  const [showRolesPopup, setShowRolesPopup] = useState(false);
  const [myStrenghts, setMyStrenghts] = useState([]);
  const [selectedStrength, setSelectedStrength] = useState([]);
  const [myRoles, setMyRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasPermissions, setHasPermissions] = useState(true);

  const [profile, setProfile] = useContext(ProfileContext);

  const {
    profileTypeData: { hasVideoFunction, videosInformation } = {},
    masterData: { translationPrefix } = {}
  } = profile;

  const exampleVideos = getTranslation(t, `videoPage`, translationPrefix, true, id - 1, 'examples');

  const addListItem = async (listItem, listType) => {
    let tempProfile = { ...profile };
    if (!tempProfile.videos) tempProfile.videos = { videos: [] };
    if (!tempProfile.videos.videos) tempProfile.videos.videos = [];

    if (listType === 'strengths') {
      if (myStrenghts.find((item) => item._id === listItem._id)) {
        let data = [...selectedStrength];
        data.push(listItem);
        setSelectedStrength(data);

        // Upload
        await linkStrengthToVideo({ strengthId: listItem._id });

        if (tempProfile.videos?.videos?.[0]) {
          tempProfile.videos.videos[0].strengthId = { ...listItem };

          setProfile({ ...tempProfile });
        } else {
          tempProfile.videos.videos[0] = { strengthId: { ...listItem } };
          setProfile({ ...tempProfile });
        }
      }
    } else if (listType === 'roles') {
      if (myRoles.find((item) => item._id === listItem._id)) {
        let data = [...selectedRole];
        data.push(listItem);
        setSelectedRole(data);

        // Upload
        await linkRoleToVideo({ roleId: listItem._id, videoID: '2' });

        if (tempProfile.videos?.videos?.[1]) {
          tempProfile.videos.videos[1].roleId = { ...listItem };

          setProfile({ ...tempProfile });
        } else {
          if (!tempProfile.videos?.videos?.[0]) {
            tempProfile.videos.videos[0] = { strengthId: { _id: '' } };
          }

          tempProfile.videos.videos[1] = { roleId: { ...listItem } };
          setProfile({ ...tempProfile });
        }
      }
    }
  };

  const removeListItem = (listItem, listType) => {
    if (listType === 'strengths') {
      setSelectedStrength((prevStrengths) =>
        prevStrengths.filter((item) => item._id !== listItem._id)
      );
    } else if (listType === 'roles') {
      setSelectedRole((prevRoles) => prevRoles.filter((item) => item._id !== listItem._id));
    }
  };

  const getStrengthsAndRoles = async () => {
    try {
      setLoading(true);

      const {
        data: { data: strengthData }
      } = await getStrengths(profile?.masterOrganization);
      let filteredStrengths = strengthData
        .filter(({ _id }) => profile.strengths.includes(_id))
        .map(({ _id, ...item }) => ({
          name: item[`name_${i18n.language}`],
          _id,
          ...item
        }));

      const sortedStrengths = getSortedList(filteredStrengths, currentLanguage);

      setMyStrenghts(sortedStrengths);

      const {
        data: { data: rolesData }
      } = await getRoles(profile?.masterOrganization);

      let filteredRoles = rolesData
        .filter(({ _id }) => profile.roles.includes(_id))
        .map(({ _id, ...item }) => ({
          name: item[`name_${i18n.language}`],
          _id,
          ...item
        }));

      const sortedRoles = getSortedList(filteredRoles, currentLanguage);

      setMyRoles(sortedRoles);
    } catch (e) {
      console.log('ERROR', e);
    } finally {
      setLoading(false);
    }
  };

  const checkPermissions = () => {
    if (navigator?.mediaDevices?.getUserMedia) {
      const permissions = navigator?.mediaDevices?.getUserMedia({ audio: true, video: true });
      console.log('permissions', permissions);

      permissions
        .then((stream) => {
          console.log('accepted the permissions');
          setHasPermissions(true);
          stream?.getTracks()?.forEach((track) => track?.stop());
        })
        .catch((err) => {
          setHasPermissions(false);
          console.log(`${err.name} : ${err.message}`);
        });
    } else {
      navigator.permissions
        .query({ name: 'microphone' })
        .then((result) => {
          if (result.state !== 'granted') {
            console.log('Audio permissions not granted');
            setHasPermissions(false);
          }
        })
        .catch((error) => {
          console.error('Error checking audio permissions:', error);
          setHasPermissions(false);
        });

      navigator.permissions
        .query({ name: 'camera' })
        .then((result) => {
          if (result.state !== 'granted') {
            console.log('Video permissions not granted');
            setHasPermissions(false);
          }
        })
        .catch((error) => {
          console.error('Error checking video permissions:', error);
          setHasPermissions(false);
        });
    }
  };

  useEffect(() => {
    if (!profile?.loaded) {
      navigate('/');
      return;
    }

    if (!hasVideoFunction || !videosInformation?.[id - 1]?.isActive) {
      navigate('/my-profile');
      return;
    }

    const videoId = Number(id);

    if (videoId === 1 || videoId === 2) {
      getStrengthsAndRoles();

      // Set initial selected strength
      if (videoId === 1 && profile?.videos?.videos?.[0]?.strengthId) {
        setSelectedStrength([profile.videos.videos[0].strengthId]);
      }

      // Set initial selected role
      if (videoId === 2 && profile?.videos?.videos?.[1]?.roleId) {
        setSelectedRole([profile.videos.videos[1].roleId]);
      }
    }
  }, [profile?.loaded, id]);

  useEffect(() => {
    checkPermissions();
  }, []);

  return (
    <div>
      <h1
        className="text-xl font-bold text-black mb-2"
        dangerouslySetInnerHTML={{
          __html: getTranslation(t, `videoPage`, translationPrefix, true, id - 1, 'heading')
        }}
      />

      {Array.isArray(exampleVideos) && exampleVideos?.length > 0 && (
        <div className="flex gap-3 flex-wrap my-3">
          {exampleVideos.map(({ title, videoURL }, index) => {
            if (!title || !videoURL) return null;

            return (
              <Link
                key={index}
                to={`/example-video/${videoURL}`}
                className="px-2 py-1 rounded-md border border-black text-black font-semibold">
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </Link>
            );
          })}
        </div>
      )}

      <p
        className="text-base text-cblack-400 mb-8"
        dangerouslySetInnerHTML={{
          __html: getTranslation(t, `videoPage`, translationPrefix, true, id - 1, 'subHeading')
        }}
      />

      <>
        {Number(id) === 1 && (
          <>
            <p
              className="text-lg text-black font-semibold mb-4"
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, `videoInfo.strengthTalkAbout`, translationPrefix)
              }}
            />
            <div
              className="cursor-pointer bg-grey-100 mb-3 rounded-full px-4 py-3 text-lg text-black font-bold flex items-center justify-between"
              onClick={() => setShowStrengthsPopup(true)}>
              <span className="">
                {profile?.videos?.videos?.[0]?.strengthId
                  ? profile?.videos?.videos?.[0]?.strengthId?.[`name_${i18n.language}`]
                  : getTranslation(t, 'videoInfo.selectStrength', translationPrefix)}
              </span>
              <span className="cursor-pointer">
                {profile?.videos?.videos?.[0]?.strengthId?._id ? <FaRedo /> : <DownArrowIcon />}
              </span>
            </div>
          </>
        )}

        {Number(id) === 2 && (
          <div
            className="cursor-pointer bg-grey-100 mb-3 rounded-full px-4 py-3 text-lg text-black font-bold flex items-center justify-between"
            onClick={() => setShowRolesPopup(true)}>
            <span className="">
              {profile?.videos?.videos?.[1]?.roleId
                ? profile?.videos?.videos?.[1]?.roleId?.[`name_${i18n.language}`]
                : getTranslation(t, 'videoInfo.selectRole', translationPrefix)}
            </span>
            <span className="cursor-pointer">
              {profile?.videos?.videos?.[1]?.roleId?._id ? <FaRedo /> : <DownArrowIcon />}
            </span>
          </div>
        )}

        <FullVideoUploadFlow
          videoID={Number(id)}
          videosInformation={videosInformation}
          orgPrefix={translationPrefix}
          allowedToRecord={
            (Number(id) === 1 && !selectedStrength.length) ||
            (Number(id) === 2 && !selectedRole.length)
              ? false
              : true
          }
        />
      </>

      {showStrengthsPopup && (
        <Popups.OptionSelectorPopup
          handler={() => setShowStrengthsPopup(false)}
          listItems={myStrenghts}
          listType={'strengths'}
          addListItem={addListItem}
          removeListItem={removeListItem}
          selectedItems={selectedStrength}
          maxItems={1}
          orgPrefix={translationPrefix}
        />
      )}

      {showRolesPopup && (
        <Popups.OptionSelectorPopup
          handler={() => setShowRolesPopup(false)}
          listItems={myRoles}
          listType={'roles'}
          addListItem={addListItem}
          removeListItem={removeListItem}
          selectedItems={selectedRole}
          maxItems={1}
          orgPrefix={translationPrefix}
        />
      )}

      {!hasPermissions && (
        <Popups.HelpLinePopup
          icon={<VideoPermissionsIcon />}
          title={getTranslation(t, 'videoPermission.title', translationPrefix)}
          description={getTranslation(t, 'videoPermission.description', translationPrefix)}
        />
      )}

      {loading && <Loader transparent={true} />}
    </div>
  );
  //   if (Number(id) === 1 && showStrengthSelectionScreen)
  //     return <Popups.VideoSupport onNext={() => setShowStrengthSelectionScreen(false)} />;

  //   if (Number(id) === 2 && showRoleSelectionScreen)
  //     return <Popups.RolesSelection videoID={id} onNext={() => setShowRoleSelectionScreen(false)} />;

  //   return <FullVideoUploadFlow videoID={Number(id)} />;
}
