export const VideosData = {
  'video-comp-1': {
    questionire: {
      mainSection: {
        heading: 'Je me présente...',
        description: '',
        options: []
      },
      secondSection: {
        heading: 'Présente-toi !',
        description: `Imagine-toi ce que tu aimerais savoir d’une autre 
        personne qui participe à la compétition et enregistre
        cela. <br />
        Dis par exemple qui tu es, d’où tu viens, quels sont 
        tes hobbys, ce que tu aimes faire, ce qui t’a poussé
        ou qui t’a motivé à participer à la compétition des 
        métiers etc.`,
        note: 'Si tu as des questions ou besoin de support <br /> technique, adresse-toi à Hugo Delouche :'
      }
    },
    popup: {
      title: 'Tu peux parler de ...',
      description: `Pourquoi j’affectionne cette force. Comment je 
      l’ai découverte. Pourquoi elle est importante pour moi 
      dans mon quotidien professionnel. …`
    },
    nextPage: '/my-profile'
  },
  'video-1': {
    questionire: {
      mainSection: {
        heading: 'Ma force préférée',
        description: 'Laisse-toi inspirer par un des exemples suivants',
        options: [
          {
            name: 'Lilian',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167654408525069823676119__92E8A52B-11CF-4B04-A5F4-4B3F0F7E4CCE.MOV'
          },
          {
            name: 'Victor',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167640341069269809612792__C7F01ED0-EED8-48F3-A576-B697FA6D2B5C.MOV'
          },
          {
            name: 'Gwenaelle',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167672725743020230218_143242.mp4'
          },
          {
            name: 'Bertrand',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167671711802469840988027__F7AF15A6-1DBA-4EC2-AB2D-0BDAF6880090.MOV'
          }
        ]
      },
      secondSection: {
        heading: 'Tu peux parler de ...',
        description: `Pourquoi j’affectionne cette force. Comment je 
            l’ai découverte. Pourquoi elle est importante pour moi 
            dans mon quotidien professionnel. …`,
        note: (
          <>
            <span className="font-bold">Important :</span> N’oublie pas d’enregistrer la vidéo en
            format portrait !
          </>
        )
      }
    },
    popup: {
      title: 'Tu peux parler de ...',
      description: `Pourquoi j’affectionne cette force. Comment je 
      l’ai découverte. Pourquoi elle est importante pour moi 
      dans mon quotidien professionnel. …`
    },
    nextPage: '/video-2'
  },
  'video-2': {
    questionire: {
      mainSection: {
        heading: 'Mon rôle professionnel préféré',
        description: 'Laisse-toi inspirer par un des exemples suivants:',
        options: [
          {
            name: 'Lilian',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167654506312969823773147__353853A2-46ED-4433-89AD-08653E658E9F.MOV'
          },
          {
            name: 'Victor',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167640387767869809660041__FAAE8D81-5E58-45B0-85A2-1F64C9277770.MOV'
          },
          {
            name: 'Gwenaelle',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167688936518720230220_113523.mp4'
          },
          {
            name: 'Bertrand',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167671724761169841001002__CA730EB5-F431-464A-B697-A5CF8F331BC5.MOV'
          }
        ]
      },
      secondSection: {
        heading: 'Tu peux parler de ...',
        description: `Pourquoi j’aime assumer ce rôle professionnel. 
        Quelles possibilités s’ouvrent à moi grâce à 
        cette aptitude. …`,
        note: (
          <>
            <span className="font-bold">Note : </span>
            Un rôle professionnel définit une aptitude, une facilité de mettre en oeuvre une force
            au quotidien.
          </>
        ),
        noteFirst: true
      }
    },
    popup: {
      heading: 'Tu peux parler de ...',
      description: `Pourquoi j’aime assumer ce rôle professionnel. 
      Quelles possibilités s’ouvrent à moi grâce à 
      cette aptitude.`
    },
    nextPage: '/video-3'
  },
  'video-3': {
    questionire: {
      mainSection: {
        heading: 'Ma scolarité',
        description: 'Laisse-toi inspirer par un des exemples suivants:',
        options: [
          {
            name: 'Lilian',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167654560676169823832212__A0D543ED-B4C8-47EB-870A-E4AC9FB6C615.MOV'
          },
          {
            name: 'Victor',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167640410288269809676184__186E5D6B-3F68-4750-8562-5CF6F850A0FC.MOV'
          },
          {
            name: 'Gwenaelle',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167688949747920230220_113711.mp4'
          },
          {
            name: 'Bertrand',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167671732044369841008255__144EF1D8-A841-4869-854F-44BB2864A17E.MOV'
          }
        ]
      },
      secondSection: {
        heading: 'Tu peux parler de ...',
        description: `Comment j'ai vécu ma scolarité (école, hobbies, 
          amis etc.). Les cours que j’aimais ou que je détestait. 
          Ma relation avec mes enseignants.`
      }
    },
    popup: {
      heading: 'Tu peux parler de ...',
      description: `Comment j'ai vécu ma scolarité (école, hobbies, 
        amis etc.). Les cours que j’aimais ou que je détestait. 
        Ma relation avec mes enseignants.`
    },
    nextPage: '/video-4'
  },
  'video-4': {
    questionire: {
      mainSection: {
        heading: 'Ma formation professionnelle',
        description: 'Laisse-toi inspirer par un des exemples suivants:',
        options: [
          {
            name: 'Lilian',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167654739840869824011792__AA55E873-AB99-4525-8F0C-26607DCBCA72.MOV'
          },
          {
            name: 'Victor',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167640452168669809721377__9D91DD60-2254-499F-AD49-32257A0A9096.MOV'
          },
          {
            name: 'Gwenaelle',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167688959404520230220_113900.mp4'
          },
          {
            name: 'Bertrand',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167671740570769841016669__DD4E43E0-18D3-4E9D-A575-BB4D166CB502.MOV'
          }
        ]
      },
      secondSection: {
        heading: 'Tu peux parler de ...',
        description: `Pourquoi j’ai choisi ce métier. Qu’est ce que 
        j’affectionne le plus. Ma plus belle expérience. 
        Ma relation avec mes supérieurs/formateurs.`,
        note: (
          <>
            <span className="font-bold">Note :</span> Compétitions exclues ! Tu vas parler des
            compétitions dans la prochaine vidéo.
          </>
        ),
        noteFirst: true
      }
    },
    popup: {
      heading: 'Tu peux parler de ...',
      description: `Pourquoi j’ai choisi ce métier. Qu’est ce que 
      j’affectionne le plus. Ma plus belle expérience. 
      Ma relation avec mes supérieurs/formateurs.`
    },
    nextPage: '/video-5'
  },
  'video-5': {
    questionire: {
      mainSection: {
        heading: 'Mon parcours de CHAMPION',
        description: 'Laisse-toi inspirer par un des exemples suivants:',
        options: [
          {
            name: 'Lilian',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167654833990269824082443__544E4F9E-F33A-4E44-B977-D033C15C8400.MOV'
          },
          {
            name: 'Victor',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167640481430969809747731__4EA4A0DF-F16D-4561-9B7E-AFBA9842E8A2.MOV'
          },
          {
            name: 'Gwenaelle',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167688970761820230220_114051.mp4'
          },
          {
            name: 'Bertrand',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167671750876069841025371__4FB853CD-D3EC-4F85-9F23-A216E98298F6.MOV'
          }
        ]
      },
      secondSection: {
        heading: 'Tu peux parler de ...',
        description: `Pourquoi et comment ai-je intégré les compétitions. 
        Quelles sont les qualités qui font de moi un bon 
        compétiteur. Mes succès. Le moment qui m’a le plus 
        marqué. Ma relation avec mes experts/formateurs.`
      }
    },
    popup: {
      heading: 'Tu peux parler de ...',
      description: `Pourquoi et comment ai-je intégré les compétitions. 
      Quelles sont les qualités qui font de moi un bon 
      compétiteur. Mes succès. Le moment qui m’a le plus 
      marqué. Ma relation avec mes experts/formateurs.`
    },
    nextPage: '/video-6'
  },
  'video-6': {
    questionire: {
      mainSection: {
        heading: 'Ma mission future',
        description: 'Laisse-toi inspirer par un des exemples suivants:',
        options: [
          {
            name: 'Lilian',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167654901325269824168046__4D27FFDA-6CE6-4313-BBBB-BDFAB355FAB7.MOV'
          },
          {
            name: 'Victor',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167640500118169809766719__59EF2761-9A1D-482C-9C5F-00CD9AE8F418.MOV'
          },
          {
            name: 'Gwenaelle',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167688977365620230220_114215.mp4'
          },
          {
            name: 'Bertrand',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167640359793869809636629__8CE2BB3A-BC24-4ABB-BADD-B10677CFDD8E.MOV'
          }
        ]
      },
      secondSection: {
        heading: 'Tu peux parler de ...',
        description: `Mon travail actuel. Ce qui me passione.
        Si compétition à venir: Je suis motivé pour les 
        EuroSkills ou WorldSkills. Ce que j’aimerais y 
        accomplir. Si compétition passée: Comment je me 
        vois dans 5 ans. Ce que j’aimerais encore accomplir.`
      }
    },
    popup: {
      heading: 'Tu peux parler de ...',
      description: `Mon travail actuel. Ce qui me passione.
      Si compétition à venir: Je suis motivé pour les 
      EuroSkills ou WorldSkills. Ce que j’aimerais y 
      accomplir. Si compétition passée: Comment je me 
      vois dans 5 ans. Ce que j’aimerais encore accomplir.`
    },
    nextPage: '/video-7'
  },
  'video-7': {
    questionire: {
      mainSection: {
        heading: 'Ce que j’aimerais encore vous dire …',
        description: 'Laisse-toi inspirer par un des exemples suivants:',
        options: [
          {
            name: 'Lilian',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167655005992369824276780__EAF7C236-D951-4CE6-9181-8D64AC2A3967.MOV'
          },
          {
            name: 'Victor',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167640534030969809800998__8E66652B-2765-48B1-A2B9-27EE738B7050.MOV'
          },
          {
            name: 'Gwenaelle',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167688984521220230220_114333.mp4'
          },
          {
            name: 'Bertrand',
            url: 'https://worldskills-videos.s3.eu-central-1.amazonaws.com/profile-videos/167671775003169841051725__B2E224A9-4655-4E30-A6FB-83831F6267ED.MOV'
          }
        ]
      },
      secondSection: {
        heading: 'Tu peux parler de ...',
        description: (
          <>
            J’aimerais encore vous dire …<br />
            Ce qui me tient à coeur … <br />
            Mon hobby ...
            <br />
            Ma musique préférée ...
            <br />
            Le film a voir ...
            <br />
            Le livre à lire ...
            <br />
            Mon partenaire dans les compétitions ...
            <br />
            Tout élément qui t’importe.`,
          </>
        )
      }
    },
    popup: {
      heading: 'Tu peux parler de ...',
      description: (
        <>
          J’aimerais encore vous dire …<br />
          Ce qui me tient à coeur … <br />
          Mon hobby ...
          <br />
          Ma musique préférée ...
          <br />
          Le film a voir ...
          <br />
          Le livre à lire ...
          <br />
          Mon partenaire dans les compétitions ...
          <br />
          Tout élément qui t’importe.`,
        </>
      )
    },
    nextPage: '/my-profile'
  }
};
