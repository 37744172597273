import VideoRecord from './VideoRecord';
import VideoTrimmer from './VideoTrimmer';
import VideoQuestionaire from './VideoQuestionaire';
import VideoUploader from './VideoUploader';

const Video = {
  VideoRecord,
  VideoTrimmer,
  VideoQuestionaire,
  VideoUploader
};

export default Video;
