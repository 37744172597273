import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ProfileContext } from '../contexts/ProfileContext';

import { Popups } from '../components';
import { AngleIcon, AudioIcon, TakesIcon, ThumbnailsIcon } from '../components/icons';
import { getTranslation } from '../utils';

export default function PgVideoTips() {
  const { id } = useParams();

  const { t } = useTranslation();

  const [profile] = useContext(ProfileContext);

  const { masterData: { translationPrefix } = {} } = profile;

  return (
    <Popups.FullPagePopup closeLink={`/video/${id}`}>
      <h2
        className="text-xl font-bold text-black mb-8"
        dangerouslySetInnerHTML={{
          __html: getTranslation(t, 'videoTips.heading', translationPrefix)
        }}
      />
      <h3
        className="text-xl font-bold text-black"
        dangerouslySetInnerHTML={{
          __html: getTranslation(t, 'videoTips.forTheVideos', translationPrefix)
        }}
      />
      <p
        className="text-lg text-grey-200"
        dangerouslySetInnerHTML={{
          __html: getTranslation(t, 'videoTips.recordings', translationPrefix)
        }}
      />

      {/* Slider */}
      <div className="relative min-h-[400px]">
        <div className="absolute left-0 top-0 w-[110%] flex flex-row overflow-x-auto gap-4 my-6 pr-12">
          <div
            className={`block border-[1px] w-[70%] min-w-[70%] max-w-[70%] border-black rounded-xl p-6`}>
            <span className="block mb-6">
              <AudioIcon />
            </span>
            <p
              className="text-lg font-bold text-black mb-2"
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, `videoTips.audio.heading`, translationPrefix)
              }}
            />
            <p
              className="text-base font-normal text-grey-200 mb-10"
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, `videoTips.audio.description`, translationPrefix)
              }}
            />
          </div>
          <div
            className={`block border-[1px] w-[70%] min-w-[70%] max-w-[70%] border-black rounded-xl p-6`}>
            <span className="block mb-6">
              <AngleIcon />
            </span>
            <p
              className="text-lg font-bold text-black mb-2"
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, `videoTips.angle.heading`, translationPrefix)
              }}
            />
            <p
              className="text-base font-normal text-grey-200 mb-10"
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, `videoTips.angle.description`, translationPrefix)
              }}
            />
          </div>
          <div
            className={`block border-[1px] w-[70%] min-w-[70%] max-w-[70%] border-black rounded-xl p-6`}>
            <span className="block mb-6">
              <TakesIcon />
            </span>
            <p
              className="text-lg font-bold text-black mb-2"
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, `videoTips.takes.heading`, translationPrefix)
              }}
            />
            <p
              className="text-base font-normal text-grey-200 mb-10"
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, `videoTips.takes.description`, translationPrefix)
              }}
            />
          </div>
          <div
            className={`block border-[1px] w-[70%] min-w-[70%] max-w-[70%] border-black rounded-xl p-6`}>
            <span className="block mb-6">
              <ThumbnailsIcon />
            </span>
            <p
              className="text-lg font-bold text-black mb-2"
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, `videoTips.thumbnails.heading`, translationPrefix)
              }}
            />
            <p
              className="text-base font-normal text-grey-200 mb-10"
              dangerouslySetInnerHTML={{
                __html: getTranslation(t, `videoTips.thumbnails.description`, translationPrefix)
              }}
            />
          </div>
        </div>
      </div>

      <div
        className="mt-6"
        dangerouslySetInnerHTML={{
          __html: getTranslation(t, 'videoTips.supportContent', translationPrefix)
        }}
      />
    </Popups.FullPagePopup>
  );
}
