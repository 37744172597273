import React, { useState, useEffect, useContext } from 'react';
import markerSDK from '@marker.io/browser';

import { ProfileContext } from '../../contexts/ProfileContext';

import bug from '../../assets/svgLogos/bug.svg';

const BugReport = () => {
  const [widget, setWidget] = useState(null);

  const [profile] = useContext(ProfileContext);

  useEffect(() => {
    (async () => {
      const w = await markerSDK.loadWidget({ project: '6627b80dd99a504db2d7d6f0' });
      w.hide();
      w.on('show', function listener() {
        w.hide();
      });
      setWidget(w);
    })();
  }, []);

  useEffect(() => {
    if (profile?.email && widget) {
      widget.setReporter({
        email: profile?.email,
        fullName: `${profile?.firstName} ${profile?.familyName}`
      });
    }
  }, [profile, widget]);

  if (!widget) return null;

  return (
    <button className="fixed bottom-4 right-4 z-50 w-12 h-12 cursor-pointer outline-none">
      <img src={bug} alt="bug" onClick={() => widget?.capture('advanced')} />
    </button>
  );
};

export default BugReport;
