import React, { useContext, useEffect, useRef, useState } from 'react';
import Avatar from '../components/ui/Avatar';
import Button from '../components/ui/button';
import ImageCropPopup from '../components/popups/ImageCropPopup';
import { ProfileContext } from '../contexts/ProfileContext';
import { toast } from 'react-toastify';
import { getUploadingFileData, postUserImageLink, uploadFileToAWS } from '../service';
import { useTranslation } from 'react-i18next';
import { Popups } from '../components';
import { getTranslation } from '../utils';

const AWS_URL = process.env.REACT_APP_AWS_URL;

export default function PgProfileImage() {
  const { t } = useTranslation();
  const imgRef = useRef();
  const [profile, setProfile] = useContext(ProfileContext);
  const [avatar, setAvatar] = useState('');
  const [croppedImg, setCroppedImg] = useState(null);
  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const [showImgCropper, setShowImgCropper] = useState(false);
  const [loading, setLoading] = useState(false);

  const { masterData: { translationPrefix } = {} } = profile;

  useEffect(() => {
    if (profile?.loaded && !profile?.image) {
      setAvatar('./images/avatar.png');
      return;
    } else {
      setAvatar(AWS_URL + 'profile-images/' + profile.image);
    }
  }, [profile?.loaded]);

  const buttonHandler = async () => {
    if (!croppedImg && !profile.image) return;

    setLoading(true);

    let imgBlob = croppedImageBlob;
    const body = {
      type: imgBlob.type,
      name: 'img.png',
      isProfileImage: true
    };
    try {
      const {
        data: { url, finalName }
      } = await getUploadingFileData(body);
      await uploadFileToAWS(url, imgBlob, body.type);
      await postUserImageLink({
        image: finalName
      });
      // Request to save data in backend passo
      toast.success(getTranslation(t, 'imageProfile.uploaded', translationPrefix));
      setCroppedImageBlob(null);
      setCroppedImg(null);
      setProfile({
        ...profile,
        image: finalName,
        isUpdatedForPDF: true,
        validationReduested: false
      });
    } catch (error) {
      console.log('Upload Error', error);
      toast.error('Error with uploading the profile picture!');
    } finally {
      setLoading(false);
    }
  };

  const imgSetter = (blob) => {
    setCroppedImageBlob(blob);
    var img = URL.createObjectURL(blob);
    setCroppedImg(img);
    setAvatar(img);
    setShowImgCropper(false);
  };

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      var imgFile = e.target.files[0];
      var imgURL = URL.createObjectURL(imgFile);
      setCroppedImg(imgURL);
      setAvatar(imgURL);
      setShowImgCropper(true);
    }
  };

  const hideImgCropper = () => {
    setShowImgCropper(false);
  };

  return (
    <Popups.FullPagePopup orgPrefix={translationPrefix}>
      <>
        <section>
          <div className="flex w-full items-center justify-between">
            <h1 className="text-2xl font-bold">
              {getTranslation(t, `imageProfile.title`, translationPrefix)}
            </h1>
            {croppedImageBlob && (
              <Button.ButtonRounded onClick={buttonHandler} loading={loading}>
                {getTranslation(t, `imageProfile.submit`, translationPrefix)}
              </Button.ButtonRounded>
            )}
          </div>

          {!showImgCropper && (
            <>
              <Avatar
                className="mx-auto px-auto my-24 mb-6 overflow-hidden rounded-full"
                img={avatar ? avatar : croppedImg}
              />
              <Button.ButtonRounded
                className="mb-3 w-max font-normal mx-auto"
                onClick={() => {
                  imgRef.current.click();
                }}>
                <p
                  className="text-white"
                  dangerouslySetInnerHTML={{
                    __html: getTranslation(t, `imageProfile.upload`, translationPrefix)
                  }}
                />
              </Button.ButtonRounded>
            </>
          )}
          {showImgCropper && (
            <ImageCropPopup
              imageUrl={avatar}
              onImageSet={imgSetter}
              turnOffCropper={hideImgCropper}
              orgPrefix={translationPrefix}
            />
          )}

          <div className="hidden">
            <input type="file" ref={imgRef} onChange={onImageChange} accept="image/*" />
          </div>
        </section>
      </>
    </Popups.FullPagePopup>
  );
}
