export default function HelpLinePopupItem({ title, description, button }) {
  return (
    <div className="text-center">
      <h2 className="font-bold text-2xl" dangerouslySetInnerHTML={{ __html: title }} />
      {description && (
        <p className="mt-5 text-lg" dangerouslySetInnerHTML={{ __html: description }} />
      )}
      {button && (
        <button className={button.className} onClick={button.onClick}>
          <span aria-label={button.label}>{button.icon}</span>
        </button>
      )}
    </div>
  );
}
