const userRoles = {
  CHAMPION: '640f030be55aad73d8cb3dc7',
  AMABASSADOR: '64101018f3cf9828e1f8bf3c',
  ABILYMPICS: '64101045b43b5e295ff259c4',
  COMPETITOR: ['6410104cb43b5e295ff259c5', '642eee5adb270f13745908a2', '642eee66db270f13745908a3'],
  COMPETITOR_REGION: ['63e5b10972a32fb6d70f3ad6'],
  CH_CHAMPION: '64a29767c6f7a0b95ed63b87',
  CH_NATIONAL_CHAMPION: '65645bfbb0640b80845244be',
  CH_AMBASSADAR: '6511227baaebee54ac41fb07',
  SWISS_TYPE: ['64a29767c6f7a0b95ed63b87', '6511227baaebee54ac41fb07', '65645bfbb0640b80845244be'],
  TEST_CASE: '651a8743a053d2754530966a',
  SSK_TEST_CASE: '65657d84a516419efae3d5ae'
};

export default userRoles;
