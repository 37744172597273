import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const profileApi = axios.create({
  baseURL: BASE_URL
});
const uninterceptedAxiosApi = axios.create({ baseURL: BASE_URL });
const regionalApi = axios.create({ baseURL: BASE_URL });

profileApi.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      try {
        localStorage.removeItem('token');
      } catch (error) {
        console.log('LS Unavailable');
      } finally {
        window.location.href = '/';
      }
    }
    return Promise.reject(error);
  }
);

regionalApi.interceptors.request.use((config) => {
  try {
    const token = localStorage.getItem('regionToken');
    config.headers.Authorization = `Bearer ${token}`;
  } catch (error) {
    console.log('LS Unavailable');
  }

  return config;
});

regionalApi.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      try {
        localStorage.removeItem('regionToken');
      } catch (error) {
        console.log('LS Unavailable');
      }
      window.location.href = '/region/login';
    }
    return Promise.reject(error);
  }
);

const fetchTranslations = () => {
  return uninterceptedAxiosApi.get(`/public/translations/profile-creation`);
};

const login = (body) => {
  return uninterceptedAxiosApi.post('/auth/login', body);
};
const verifyLink = (body) => {
  return uninterceptedAxiosApi.post('/auth/magic', body);
};

const updateUserPreferredLanguage = (body) => {
  return profileApi.patch(`/users/preferred-language`, body);
};
//Update Profile Data
const updateProfileData = (body) => {
  return profileApi.post('/users/profile', body);
};

//Get Uplaoding File Link and Final Name
const getUploadingFileData = (body) => {
  return profileApi.post('/uploader/upload', body);
};
//Upload File to the Link
const uploadFileToAWS = (url, file, contentType) => {
  return uninterceptedAxiosApi.put(url, file, {
    headers: {
      'Content-type': contentType
    }
  });
};
//Upload Video to the link
const uploadVideo = (url, videoFile, setUplaodProgress, fileType) => {
  return uninterceptedAxiosApi.put(url, videoFile, {
    headers: {
      'Content-type': fileType
    },
    onUploadProgress: (progressEvent) => {
      setUplaodProgress((progressEvent.loaded / videoFile.size) * 100);
    }
  });
};
//Post Video Link for the Recording
const postVideoLink = (body) => {
  return profileApi.post('/users/video', body);
};
//Post User Image
const postUserImageLink = (body) => {
  return profileApi.post('/users/user-image', body);
};
//Post Strenghts and Roles
const postStrengthsAndRoles = (body) => {
  return profileApi.post('/users/strengths-role', body);
};

const getUserProfile = () => {
  return profileApi.get('/users/profile');
};

const requestValidation = () => {
  return profileApi.get(`/users/request-validation`);
};

const getRegions = (organization = '650bd89479ba3ae92524955b') => {
  return profileApi.get(`/public/regions?organization=` + organization);
};

const getProfessions = (organization = '650bd89479ba3ae92524955b') => {
  return profileApi.get(`/public/professions`, { params: { organization } });
};
const getRoles = (masterOrganization = '650bd89479ba3ae92524955b') => {
  return profileApi.get(`/public/roles?organization=` + masterOrganization);
};

const getStrengths = (masterOrganization = '650bd89479ba3ae92524955b') => {
  return profileApi.get(`/public/strengths?organization=` + masterOrganization);
};

const generateProfilePDF = (body) => {
  return profileApi.post('/public/generate-profile-pdf', body);
};

const videoSupportContact = (body) => {
  return profileApi.post(`/generic/video-support`, body);
};

const linkStrengthToVideo = (body) => {
  return profileApi.post(`/users/video-strength`, body);
};
const linkRoleToVideo = (body) => {
  return profileApi.post(`/users/video-role`, body);
};

const regionalLogin = (body) => {
  return profileApi.post('/auth/regional-video-login', body);
};

const regionalLoginMagicVerify = (body) => {
  return profileApi.post('/auth/regional-video-magic', body);
};

const getUserID = () => {
  return regionalApi.get('/regional-videos/get-region-user');
};

const uploadRegionalVideo = (body) => {
  return regionalApi.post('/regional-videos/upload-video', body);
};

const getRegionData = (body) => {
  return regionalApi.post('/regional-videos/get-region-data', body);
};

const regionSupportContact = (body) => {
  return regionalApi.post(`/generic/region-support`, body);
};

const getWeekDays = () => {
  return regionalApi.get('/regional-videos/week-days');
};

const getProfessionalAssociation = () => {
  return profileApi.get(`/public/professional-association`);
};
const getFunction = (organization = '650bd7c379ba3ae92524955a') => {
  return profileApi.get(`/public/functions`, { params: { organization } });
};
const getSpecialJobTitles = (organization = '650bd7c379ba3ae92524955a') => {
  return profileApi.get(`/public/special-job-titles`, { params: { organization } });
};
const getVocationalSchool = () => {
  return profileApi.get(`/public/vocational-school`);
};

const storeStats = (body) => {
  return uninterceptedAxiosApi.post('/public/stats', body);
};

export {
  fetchTranslations,
  login,
  verifyLink,
  updateUserPreferredLanguage,
  updateProfileData,
  getUploadingFileData,
  uploadFileToAWS,
  uploadVideo,
  postVideoLink,
  postUserImageLink,
  postStrengthsAndRoles,
  getUserProfile,
  requestValidation,
  getRegions,
  getProfessions,
  videoSupportContact,
  getRoles,
  getStrengths,
  generateProfilePDF,
  linkStrengthToVideo,
  linkRoleToVideo,
  regionalLogin,
  regionalLoginMagicVerify,
  getUserID,
  uploadRegionalVideo,
  getRegionData,
  getWeekDays,
  getProfessionalAssociation,
  getFunction,
  getSpecialJobTitles,
  getVocationalSchool,
  regionSupportContact,
  storeStats
};
