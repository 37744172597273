import React, { useState, useContext } from 'react';
import { ProfileContext } from '../../contexts/ProfileContext';
export default function Avatar({ className, img }) {
  const [loaded, setLoaded] = useState(false);
  const [profile] = useContext(ProfileContext);
  const { type } = profile;

  return (
    <div className={`avatar ${className}`}>
      <img
        className="img"
        src={img}
        onLoad={() => {
          setLoaded(true);
        }}
      />
      {/* <img className="flag" src={`./images/flag/${type}Flag.png`} /> */}
      {!loaded && <span className="loading"></span>}
    </div>
  );
}

Avatar.defaultProps = {
  className: '',
  img: 'https://placeholder.com/assets/images/150x150-2-500x500.png'
};
