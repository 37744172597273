import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty, findIndex } from 'lodash';

import { ProfileContext } from '../../contexts/ProfileContext';
import DownloadPDF from '../../components/popups/downloadPDF';
import { useTranslation } from 'react-i18next';

import {
  CheckmarkIcon,
  PendingVideoIcon,
  ProcessingVideoIcon,
  CompleteVideoIcon,
  EditVideoIcon
} from '../icons';
import { checkVideoUrlValidity, getTranslation } from '../../utils';
import { checkRequiredFieldsInformationForm } from '../../utils/utils';

export default function MenuItems({ orgPrefix }) {
  const { t } = useTranslation();

  const [showPDFModal, setShowPDFModal] = useState(false);
  const [profile] = useContext(ProfileContext);

  const menuItems = [
    {
      title: getTranslation(t, `myProfile.portrait`, orgPrefix),
      link: '/profile-image',
      key: 'image'
    },
    {
      title: getTranslation(t, `myProfile.personal`, orgPrefix),
      link: '/profile-data',
      key: 'profileData'
    },
    {
      title: getTranslation(t, `myProfile.roles`, orgPrefix),
      link: '/strengths',
      key: 'strengths'
    }
  ];

  const {
    profileTypeData: { hasVideoFunction, videosInformation } = {},
    masterData: { brandingBackground } = {}
  } = profile;

  const handlePDFDownload = () => {
    setShowPDFModal(true);
  };

  const closePDFModal = () => {
    setShowPDFModal(false);
  };

  const validateStepCompletion = (step) => {
    if (step === 'profileData') return checkRequiredFieldsInformationForm(profile)?.length !== 0;
    if (step === 'strengths')
      return profile?.['strengths']?.length !== 3 || profile?.['roles']?.length !== 3;
    if (step === 'image') return isEmpty(profile['image']);
  };

  return (
    <div className="block w-full">
      {menuItems.map((item, index) => (
        <Link
          key={index}
          to={item.link}
          className={`flex gap-4 items-center justify-start w-full my-2 bg-gray-100 rounded-full px-4 py-3`}>
          <span className="bg-grey-200 relative w-6 h-6 rounded-full flex items-center justify-center">
            {!validateStepCompletion(item.key) && <CheckmarkIcon />}
          </span>
          <p>{item.title}</p>
        </Link>
      ))}

      {hasVideoFunction && (
        <>
          <p
            className="text-lg text-black font-bold my-4 mt-8"
            dangerouslySetInnerHTML={{
              __html: getTranslation(t, 'myProfile.videosHeading', orgPrefix)
            }}
          />
          <div className="relative min-h-[300px]">
            <div className="absolute left-0 top-0 w-[110%] flex flex-row overflow-x-auto gap-4 pr-12">
              {brandingBackground && (
                <ExampleVideoThumbnail
                  brandingBackground={brandingBackground}
                  orgPrefix={orgPrefix}
                />
              )}
              {videosInformation.map(({ isActive }, index) => {
                if (!isActive) return null;
                return (
                  <VideoThumbnail
                    key={index}
                    videoId={index + 1}
                    videos={profile?.videos?.videos}
                    orgPrefix={orgPrefix}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}

      {showPDFModal && (
        <DownloadPDF
          userId={profile._id}
          profileType={profile.profileType}
          role={profile.role}
          closeModal={closePDFModal}
        />
      )}
    </div>
  );
}

const ExampleVideoThumbnail = ({ brandingBackground, orgPrefix }) => {
  const { t } = useTranslation();

  const { videoURL, heading, subHeading } = getTranslation(t, `exampleVideo`, orgPrefix);

  const backgroundFullLink = process.env.REACT_APP_AWS_URL + brandingBackground;

  if (!videoURL) return null;

  return (
    <Link
      to={`/example-video/${videoURL}`}
      className={`block relative border-[1px] w-[70%] min-w-[70%] max-w-[70%] border-black rounded-xl overflow-hidden p-6`}>
      <img
        src={backgroundFullLink}
        className="w-full h-full block object-cover object-center absolute left-0 top-0 opacity-50"
      />

      <p
        className="text-lg font-bold text-black mb-2"
        dangerouslySetInnerHTML={{
          __html: heading
        }}
      />
      <p
        className="text-base font-normal text-grey-200 mb-10"
        dangerouslySetInnerHTML={{
          __html: subHeading
        }}
      />

      <span className="block mb-6 opacity-0 pointer-events-none">
        <ProcessingVideoIcon />
      </span>

      <div className="flex w-full justify-end">
        <EditVideoIcon />
      </div>
    </Link>
  );
};

const VideoThumbnail = ({ videoId, videos, orgPrefix }) => {
  const { t } = useTranslation();

  const VIDEO_URL = process.env.REACT_APP_AWS_URL + 'profile-videos/';

  const [status, setStatus] = useState('pending'); // pending, processing, complete

  const setVideoStatus = async () => {
    let index = findIndex(videos, ({ videoID: tVideoID }) => parseInt(tVideoID) === videoId);
    if (index >= 0) {
      const vidURL = videos[index].videoURL;
      if (vidURL === undefined) {
        setStatus('pending');
        return;
      }
      const videoURL = VIDEO_URL + vidURL;
      const isValid = await checkVideoUrlValidity(videoURL);
      if (!isValid) {
        setStatus('processing');
      } else {
        setStatus('complete');
      }
    }
  };

  useEffect(() => {
    setVideoStatus();
  }, [videoId]);

  return (
    <Link
      to={`/video/${videoId}`}
      className={`block border-[1px] w-[70%] min-w-[70%] max-w-[70%] border-black rounded-xl p-6`}>
      <span className="block mb-6">
        {status === 'pending' && <PendingVideoIcon />}
        {status === 'processing' && <ProcessingVideoIcon />}
        {status === 'complete' && <CompleteVideoIcon />}
      </span>
      <p
        className="text-lg font-bold text-black mb-2"
        dangerouslySetInnerHTML={{
          __html: getTranslation(t, `myProfile.video`, orgPrefix, true, videoId - 1, 'label')
        }}
      />
      <p
        className="text-base font-normal text-grey-200 mb-10"
        dangerouslySetInnerHTML={{
          __html: getTranslation(t, `myProfile.video`, orgPrefix, true, videoId - 1, 'sub')
        }}
      />

      <div className="flex w-full justify-end">
        <EditVideoIcon />
      </div>
    </Link>
  );
};
