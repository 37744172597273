/**
 * 📅 Last Modified: 04 Feb, 2023
 */
import React, { useEffect, useState, useRef } from 'react';
import Header from '../../components/partials/Header';
import Button from '../../components/ui/button';

export default function ReuploadVideo({ reuploadData, onContinueHandle, onCloseReupload }) {
  const url = process.env.REACT_APP_AWS_URL;
  const vidRef = useRef(null);
  const [isPlay, setIsPlay] = useState(true);

  useEffect(() => {
    const video = vidRef.current;
    if (video.paused || video.ended) {
      setIsPlay(true);
    } else {
      setIsPlay(false);
    }
    if (!video) return;
  }, []);

  const togglePlayPause = () => {
    const video = vidRef.current;
    if (!video) return;

    if (video.paused || video.ended) {
      video
        .play()
        .then(() => setIsPlay(false))
        .catch((error) => console.log('Error playing video', error));
    } else {
      video.pause();
      setIsPlay(true);
    }
  };

  const goHome = () => {
    onCloseReupload();
  };

  return (
    <section className="pb-10 max-w-md mx-auto">
      <Header type={'home'} goHome={goHome} />
      <div className="mb-10 mx-16">
        <div className="flex justify-between items-center mt-10 mb-4">
          {reuploadData?.dayName && (
            <div className="text-2xl font-bold">{reuploadData.dayName}</div>
          )}
          {reuploadData?.dayName && (
            <div className="text-base font-semibold">Vidéo {reuploadData.videoId}</div>
          )}
        </div>
        <div className="mb-4">
          <p>Une vidéo est déjà enregistrée. Vous pouvez la remplacer, si vous le désirez.</p>
        </div>
        <div className="mb-8">
          <div
            className="relative flex justify-center items-center"
            onClick={() => {
              togglePlayPause();
            }}>
            <video
              ref={vidRef}
              src={`${url}${reuploadData.videoURL} `}
              start={reuploadData.trimPoints[0]}
              end={reuploadData.trimPoints[1]}
              onTimeUpdate={(e) => {
                if (e.target.currentTime >= reuploadData.trimPoints[1]) {
                  e.target.currentTime = reuploadData.trimPoints[0];
                  e.target.play();
                }
                if (
                  e.target.currentTime < reuploadData.trimPoints[0] &&
                  reuploadData.trimPoints[0] !== 0
                ) {
                  e.target.currentTime = reuploadData.trimPoints[0];
                  e.target.play();
                }
              }}
              className="h-full w-full object-cover"
            />
            {isPlay && <img src="/images/icons/playButton.png" className={`absolute`} />}
          </div>
        </div>
        <div className="mb-10">
          <p>
            <strong>Important : </strong>Enregistrez ou téléchargez une vidéo en format portrait !
          </p>
        </div>
        <div className="mb-10">
          <Button.ButtonRounded
            onClick={() => {
              onContinueHandle();
            }}
            className="mx-auto">
            Enregistrer une vidéo
          </Button.ButtonRounded>
        </div>
      </div>
    </section>
  );
}
