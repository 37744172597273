export default function Input({
  name,
  label,
  labelClassName,
  inOneLine = false,
  className,
  error,
  touched,
  ...props
}) {
  return (
    <>
      <label htmlFor={name} className={`mt-6 ${inOneLine && 'flex items-center justify-start'}`}>
        <input
          name={name}
          id={name}
          type="checkbox"
          className={`p-2 mr-2 ${className}`}
          {...props}
        />
        <span className={`${labelClassName}`} dangerouslySetInnerHTML={{ __html: label }} />
      </label>
      {error && touched && (
        <p className="text text-red-500" data-testid="error-text">
          {error}
        </p>
      )}
    </>
  );
}
