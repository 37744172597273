export const BluePlusIcon = ({ isSelected = false }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="9" fill="#3360C5" />
      <path
        d="M9.04245 13.2851L9.04245 4.84248M4.95694 8.88535L13.2424 8.88535"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};

export const GrayPlusIcon = ({ isSelected = false }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="9" fill="rgba(51, 96, 197, 0.12)" />
      <path
        d="M9.04245 13.2851L9.04245 4.84248M4.95694 8.88535L13.2424 8.88535"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};

export const ToggleArrow = ({ isSelected = false }) => {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${isSelected ? 'text-white' : 'text-black'}`}>
      <path
        d="M6 7.5L0.803847 0.75L11.1962 0.750001L6 7.5Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
};
export const LoginIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="71"
      height="71"
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44.375 8.875H56.2083C57.7775 8.875 59.2825 9.49836 60.392 10.608C61.5016 11.7175 62.125 13.2225 62.125 14.7917V56.2083C62.125 57.7775 61.5016 59.2825 60.392 60.392C59.2825 61.5016 57.7775 62.125 56.2083 62.125H44.375"
        stroke="#191919"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.582 50.2918L44.3737 35.5002L29.582 20.7085"
        stroke="#191919"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.375 35.5H8.875"
        stroke="#191919"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CheckmarkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M4.40078 11.5997L7.60078 13.9997L14.8008 5.19971" stroke="white" strokeWidth="2" />
    </svg>
  );
};

export const PreviewIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
      <circle cx="9.5" cy="9" r="2.25" fill="#606060" stroke="#606060" strokeWidth="1.5" />
      <path
        d="M15.4236 7.94266C15.8177 8.3999 16.0147 8.62851 16.0147 9C16.0147 9.37149 15.8177 9.6001 15.4236 10.0573C14.277 11.3875 12.0542 13.5 9.5 13.5C6.9458 13.5 4.72295 11.3875 3.57643 10.0573C3.18233 9.6001 2.98528 9.37149 2.98528 9C2.98528 8.62851 3.18233 8.3999 3.57643 7.94266C4.72295 6.61246 6.9458 4.5 9.5 4.5C12.0542 4.5 14.277 6.61246 15.4236 7.94266Z"
        fill="#606060"
        stroke="#606060"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const DownArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path d="M18 9.5L12 15.5L6 9.5" stroke="black" strokeWidth="2" />
    </svg>
  );
};

export const BackIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="20" height="21" rx="5" fill="black" />
      <path d="M13 15L8.5 10.5L13 6" stroke="white" strokeWidth="2" />
    </svg>
  );
};

export const InfoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10" r="9" stroke="white" strokeWidth="2" />
      <path
        d="M10.5 5.5C10.5 5.77614 10.2761 6 10 6C9.72386 6 9.5 5.77614 9.5 5.5C9.5 5.22386 9.72386 5 10 5C10.2761 5 10.5 5.22386 10.5 5.5Z"
        fill="white"
        stroke="white"
      />
      <path d="M10 15V8" stroke="white" strokeWidth="2" />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18 6L6 18"
        stroke="#33363F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#33363F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PendingVideoIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="14" stroke="black" strokeWidth="2" strokeDasharray="4 4" />
    </svg>
  );
};

export const ProcessingVideoIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 0.470703C12.3096 0.470703 11.75 1.02376 11.75 1.706V5.41188C11.75 6.09411 12.3096 6.64717 13 6.64717C13.6904 6.64717 14.25 6.09411 14.25 5.41188V1.706C14.25 1.02376 13.6904 0.470703 13 0.470703Z"
        fill="black"
      />
      <path
        d="M13 19.0001C12.3096 19.0001 11.75 19.5532 11.75 20.2354V23.9413C11.75 24.6235 12.3096 25.1766 13 25.1766C13.6904 25.1766 14.25 24.6235 14.25 23.9413V20.2354C14.25 19.5532 13.6904 19.0001 13 19.0001Z"
        fill="black"
      />
      <path
        d="M24.25 11.5884C24.9404 11.5884 25.5 12.1414 25.5 12.8236C25.5 13.5059 24.9404 14.0589 24.25 14.0589H20.5C19.8096 14.0589 19.25 13.5059 19.25 12.8236C19.25 12.1414 19.8096 11.5884 20.5 11.5884H24.25Z"
        fill="black"
      />
      <path
        d="M6.75 12.8236C6.75 12.1414 6.19036 11.5884 5.5 11.5884H1.75C1.05964 11.5884 0.5 12.1414 0.5 12.8236C0.5 13.5059 1.05964 14.0589 1.75 14.0589H5.5C6.19036 14.0589 6.75 13.5059 6.75 12.8236Z"
        fill="black"
      />
      <path
        d="M20.0711 4.08892C20.5593 3.60651 21.3507 3.60651 21.8389 4.08892C22.3271 4.57133 22.3271 5.35347 21.8389 5.83589L19.1873 8.45634C18.6991 8.93875 17.9076 8.93875 17.4195 8.45634C16.9313 7.97393 16.9313 7.19178 17.4195 6.70937L20.0711 4.08892Z"
        fill="black"
      />
      <path
        d="M8.58054 17.191C8.09238 16.7086 7.30093 16.7086 6.81277 17.191L4.16112 19.8115C3.67297 20.2939 3.67297 21.076 4.16112 21.5584C4.64928 22.0408 5.44073 22.0408 5.92889 21.5584L8.58054 18.938C9.0687 18.4556 9.0687 17.6734 8.58054 17.191Z"
        fill="black"
      />
      <path
        d="M21.8389 19.8116C22.327 20.294 22.327 21.0761 21.8389 21.5586C21.3507 22.041 20.5593 22.041 20.0711 21.5586L17.4194 18.9381C16.9313 18.4557 16.9313 17.6735 17.4194 17.1911C17.9076 16.7087 18.6991 16.7087 19.1872 17.1911L21.8389 19.8116Z"
        fill="black"
      />
      <path
        d="M8.58066 8.45618C9.06881 7.97376 9.06881 7.19162 8.58066 6.70921L5.92901 4.08875C5.44085 3.60634 4.64939 3.60634 4.16124 4.08875C3.67308 4.57116 3.67308 5.35331 4.16124 5.83572L6.81289 8.45618C7.30104 8.93859 8.0925 8.93859 8.58066 8.45618Z"
        fill="black"
      />
    </svg>
  );
};

export const CompleteVideoIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="black" />
      <path d="M8 17L12 20L21 9" stroke="white" strokeWidth="2" />
    </svg>
  );
};

export const EditVideoIcon = () => {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15.5" r="15" fill="#E2E2E2" />
      <path d="M12 9.5L18 15.5L12 21.5" stroke="black" strokeWidth="2" />
    </svg>
  );
};

export const AudioIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#E2E2E2" />
      <rect
        x="12.75"
        y="8.25"
        width="4.5"
        height="8.25"
        rx="2.25"
        stroke="black"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 14.25C9.75 15.6424 10.3031 16.9777 11.2877 17.9623C12.2723 18.9469 13.6076 19.5 15 19.5C16.3924 19.5 17.7277 18.9469 18.7123 17.9623C19.6969 16.9777 20.25 15.6424 20.25 14.25"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 21.75V20.25"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AngleIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#E2E2E2" />
      <path
        d="M13.4832 11.8752C14.0028 12.8702 14.2661 13.979 14.2492 15.1013C14.2324 16.2236 13.9359 17.3241 13.3867 18.303"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M15 9.75C12.9089 11.8411 7.57858 14.5801 6.8363 14.9565C6.79955 14.9751 6.79955 15.0249 6.8363 15.0435C7.57858 15.4199 12.9089 18.1589 15 20.25"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M18.5 15H23.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M18.5 12L23.5 10" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M18.5 18L23.5 20" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M13.3455 11.6247C13.9068 12.5968 14.2168 13.6934 14.2475 14.8155C14.2782 15.9375 14.0286 17.0495 13.5212 18.0508"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M13.8882 16.4489C13.666 16.5084 13.433 16.5161 13.2074 16.4712C12.9817 16.4263 12.7694 16.3301 12.5869 16.19C12.4043 16.05 12.2565 15.8698 12.1547 15.6634C12.0529 15.4571 12 15.2301 12 15C12 14.7699 12.0529 14.5429 12.1547 14.3366C12.2565 14.1302 12.4043 13.95 12.5869 13.81C12.7694 13.6699 12.9817 13.5737 13.2074 13.5288C13.433 13.4839 13.666 13.4916 13.8882 13.5511"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const TakesIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#E2E2E2" />
      <path
        d="M15.8787 8.25H12.75C11.3358 8.25 10.6287 8.25 10.1893 8.68934C9.75 9.12868 9.75 9.83579 9.75 11.25V18.75C9.75 20.1642 9.75 20.8713 10.1893 21.3107C10.6287 21.75 11.3358 21.75 12.75 21.75H17.25C18.6642 21.75 19.3713 21.75 19.8107 21.3107C20.25 20.8713 20.25 20.1642 20.25 18.75V12.6213C20.25 12.3148 20.25 12.1615 20.1929 12.0236C20.1358 11.8858 20.0274 11.7774 19.8107 11.5607L16.9393 8.68934C16.7226 8.47257 16.6142 8.36418 16.4764 8.30709C16.3385 8.25 16.1852 8.25 15.8787 8.25Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M16.3915 16.5606L14.0045 15.5376C13.4106 15.2831 12.75 15.7187 12.75 16.3648V18.1351C12.75 18.7812 13.4106 19.2168 14.0045 18.9623L16.3915 17.9393C16.9976 17.6796 16.9976 16.8203 16.3915 16.5606Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 8.25V11.25C15.75 11.9571 15.75 12.3107 15.9697 12.5303C16.1893 12.75 16.5429 12.75 17.25 12.75H20.25"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const ThumbnailsIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill="#E2E2E2" />
      <path
        d="M15.8787 8.25H12.75C11.3358 8.25 10.6287 8.25 10.1893 8.68934C9.75 9.12868 9.75 9.83579 9.75 11.25V18.75C9.75 20.1642 9.75 20.8713 10.1893 21.3107C10.6287 21.75 11.3358 21.75 12.75 21.75H17.25C18.6642 21.75 19.3713 21.75 19.8107 21.3107C20.25 20.8713 20.25 20.1642 20.25 18.75V12.6213C20.25 12.3148 20.25 12.1615 20.1929 12.0236C20.1358 11.8858 20.0274 11.7774 19.8107 11.5607L16.9393 8.68934C16.7226 8.47257 16.6142 8.36418 16.4764 8.30709C16.3385 8.25 16.1852 8.25 15.8787 8.25Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M16.3915 16.5606L14.0045 15.5376C13.4106 15.2831 12.75 15.7187 12.75 16.3648V18.1351C12.75 18.7812 13.4106 19.2168 14.0045 18.9623L16.3915 17.9393C16.9976 17.6796 16.9976 16.8203 16.3915 16.5606Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 8.25V11.25C15.75 11.9571 15.75 12.3107 15.9697 12.5303C16.1893 12.75 16.5429 12.75 17.25 12.75H20.25"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export const VideoPermissionsIcon = () => {
  return (
    <svg
      className="w-full"
      width="116"
      height="118"
      viewBox="0 0 116 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.72363 0.695312L1.83887 6.80469L108.276 117.305L114.161 111.195L90.75 86.8906V78.6562L115.312 91.4062V26.5938L90.75 39.3438V25H31.1348L7.72363 0.695312ZM0.6875 25V93H74.375L66.1875 84.5H8.875V33.5H17.0625L8.875 25H0.6875ZM39.3223 33.5H82.5625V78.3906L39.3223 33.5ZM107.125 40.4062V77.5938L90.75 69.0938V48.9062L107.125 40.4062Z"
        fill="#191919"
      />
    </svg>
  );
};
