import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import { ProfileContextProvider } from './contexts/ProfileContext';

import 'react-toastify/dist/ReactToastify.css';
import 'cropperjs/dist/cropper.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <ProfileContextProvider>
      <Router>
        <App />
      </Router>
    </ProfileContextProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
